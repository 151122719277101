<template>
  <v-row class="d-flex justify-center mt-0 mx-md-2 mx-n1 mb-0">
    <v-col cols="12" class="d-flex justify-end align-center pt-0 px-1">
      <div style="font-size: 20px">
        {{ control_copy.descripcion }}
      </div>
      <v-spacer></v-spacer>
      <v-expand-x-transition>
        <v-switch
          v-if="existe && (control_copy.estado == 1 || control_copy.estado == 3) && permiso == 1"
          v-model="editar"
          label="Editar"
          class="d-flex align-center py-0 my-0"
          hide-details
          dense
          @click="editar && panel == 1 ? panel = 0 : ''"
        ></v-switch>
      </v-expand-x-transition>
    </v-col>
    <!-- DETALLE DEL CONTROL -->
    <v-expansion-panels v-model="panel">
      <v-expansion-panel readonly>
        <v-expansion-panel-header class="py-0 px-4 text-h6" style="cursor: default">
          <div>
            Detalle
          </div>
          <template v-slot:actions>
            <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
              <v-icon>fas fa-chevron-down</v-icon>
            </v-btn>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-1">
          <v-form ref="form">
            <v-row class="pt-4">
              <v-col cols="6" sm="3" class="py-0">
                <v-text-field
                  v-model="control.estado_nombre"
                  label="Estado"
                  class="mb-sm-0 mb-6"
                  :style="control_copy.estado ? `background: ${estados_control_stk.find(e => e.codigo == control_copy.estado).color}` : ''"
                  hide-details
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                v-if="control.vendedor"
                cols="6" sm="3"
                class="py-0"
              >
                <v-text-field
                  v-model="control.vendedor"
                  label="Responsable"
                  outlined
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="5" class="py-0">
                <v-text-field
                  v-model="control.deposito_nombre"
                  label="Bodega"
                  outlined
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="11" class="py-0">
                <v-textarea
                  v-model="control.descripcion"
                  label="Descripción"
                  rows="2"
                  :rules="editar ? [rules.required] : []"
                  :readonly="!editar"
                  :filled="!editar"
                  auto-grow
                  outlined
                  dense
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="pt-2 pt-sm-0">
                <v-card outlined>
                  <v-card-title class="body-1 pt-2 pb-2">
                    Disponibilidad
                  </v-card-title>
                  <v-card-text class="py-0 mt-2 pb-3">
                    <v-row>
                      <v-col cols="12" sm="6" class="py-0">
                        <span class="body-1 secondary--text">
                          Desde
                        </span>
                        <v-row class="pt-5">
                          <v-col cols="6" class="py-0">
                            <FechaPicker
                              v-model="control.fecha_desde"
                              label="Fecha"
                              :disabled="!editar"
                            />
                          </v-col>
                          <v-col cols="6" class="py-0">
                            <HoraPicker
                              v-model="control.hora_desde"
                              label="Hora"
                              :disabled="!editar"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="6" class="py-0">
                        <span class="body-1 secondary--text">
                          Hasta
                        </span>
                        <v-row class="pt-5">
                          <v-col cols="6" class="py-0">
                            <FechaPicker
                              v-model="control.fecha_hasta"
                              label="Fecha"
                              :disabled="!editar"
                            />
                          </v-col>
                          <v-col cols="6" class="py-0">
                            <HoraPicker
                              v-model="control.hora_hasta"
                              label="Hora"
                              :disabled="!editar"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                v-if="control_copy.estado != 1"
                cols="12" class="pt-2 pt-sm-0"
              >
                <v-card outlined>
                  <v-card-title class="body-1 pt-2 pb-2">
                    Log del control de stock
                  </v-card-title>
                  <v-card-text class="py-0 mt-2 pb-3">
                    <v-row>
                      <v-col cols="12" sm="6" class="py-0">
                        <span class="body-1 secondary--text">
                          Inicio
                        </span>
                        <v-row class="pt-5">
                          <v-col cols="6" class="py-0">
                            <v-text-field
                              v-model="control.usuario_inicio"
                              label="Usuario"
                              outlined
                              readonly
                              filled
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" class="py-0">
                            <v-text-field
                              v-model="control.fecha_inicio"
                              label="Fecha"
                              outlined
                              readonly
                              filled
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="6" class="py-0">
                        <span class="body-1 secondary--text">
                          Finalización
                        </span>
                        <v-row class="pt-5">
                          <v-col cols="6" class="py-0">
                            <v-text-field
                              v-model="control.usuario_fin"
                              label="Usuario"
                              outlined
                              readonly
                              filled
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" class="py-0">
                            <v-text-field
                              v-model="control.fecha_fin"
                              label="Fecha"
                              outlined
                              readonly
                              filled
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                v-else
                cols="12"
                class="pt-2 pt-sm-0"
              >
                <v-card outlined>
                  <v-card-title class="body-1 pt-2">
                    Controlar el stock de
                  </v-card-title>
                  <v-card-text class="py-0 mt-2 mb-sm-2 mb-4">
                    <v-row>
                      <template v-if="editar || control.tiene_cat == 1">
                        <v-col cols="12" sm="6" class="py-0">
                          <v-autocomplete
                            v-model="control.rubros"
                            label="Rubros"
                            item-text="nombre"
                            item-value="codigo"
                            :items="rubros"
                            :clearable="editar"
                            :readonly="!editar"
                            :filled="!editar"
                            deletable-chips
                            small-chips
                            multiple
                            outlined
                            dense
                            @change="get_categorias(); control.categorias = []"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" class="py-0">
                          <v-autocomplete
                            v-model="control.categorias"
                            label="Categorias"
                            item-text="nombre"
                            item-value="codigo"
                            :items="categorias_filtradas"
                            :clearable="editar"
                            :readonly="!editar"
                            :filled="!editar"
                            deletable-chips
                            small-chips
                            multiple
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                      </template>
                      <template v-if="editar || control.tiene_art == 1">
                        <v-col 
                          v-if="editar"
                          cols="12"
                          class="pt-0 pb-4"
                        >
                          <ArticulosActivos
                            v-model="control.articulos"
                            :disabled="!editar"
                          />
                        </v-col>
                        <v-col v-else>
                          <v-data-table
                            class="cebra mt-2"
                            sort-by="nombre"
                            :items="control.articulos"
                            :headers="[
                              { text: 'Cod. Interno', value: 'codigo', align: 'end' },
                              { text: 'Nombre', value: 'nombre' },
                              { text: 'Categoría', value: 'categoria_nombre' },
                            ]"
                            dense
                          ></v-data-table>
                        </v-col>
                      </template>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- ARTICULOS DEL CONTROL -->
    <v-col id="test" cols=12 class="px-0 pb-0 pt-4">
      <v-card>
        <v-card-title class="pb-3">
          Artículos
          <div class="ml-4">
            <SearchDataTable
              v-model="search"
            />
          </div>
          <!-- solo muestra estos botones si el estado es pendiente/en curso/guardado y existe -->
          <template v-if="(control_copy.estado == 1 || control_copy.estado == 2 || control_copy.estado == 3) && existe">
          <v-btn
            v-if="!serie"
            color="success"
            class="ml-0 ml-sm-3 mt-3 mt-sm-0"
            :disabled="readonly || !permite_art"
            small
            @click="dialog_sobrante = true"
          >
            <v-icon left small>fas fa-plus</v-icon>
            Agregar
          </v-btn>
          <v-spacer></v-spacer>
          <div
            v-if="serie"
            class="d-flex mt-3 mt-md-0"
          >
            <v-btn
              :disabled="readonly || series.filter(s => s.estado != 1).length > 0"
              small
              @click="dialog_archivo = true"
            >
              <v-icon small left>fas fa-file-upload</v-icon>
              Subir archivo
            </v-btn>
            <v-btn
              color="cyan"
              class="ml-3"
              :disabled="readonly || bloquear_asistido"
              :dark="!(readonly || bloquear_asistido)"
              small
              @click="dialog_asistido = true"
            >
              <v-icon small left>fas fa-barcode</v-icon>
              Asistido
            </v-btn>
          </div>
          <div
            v-else
            class="d-flex mt-3 mt-md-0"
          >
            <v-btn
              :disabled="control_copy.estado != 2 && control_copy.estado != 3"
              small
              @click="descargar_modelo()"
            >
              <v-icon small left>fas fa-download</v-icon>
              Descargar planilla
            </v-btn>
            <v-btn
              color="cyan"
              class="ml-3"
              :disabled="readonly || (control_copy.estado != 2 && control_copy.estado != 3)"
              :dark="!(readonly || (control_copy.estado != 2 && control_copy.estado != 3))"
              small
              @click="dialog_asistido_art = true"
            >
              <v-icon small left>fas fa-barcode</v-icon>
              Asistido
            </v-btn>
          </div>
          <v-btn
            color="success"
            class="ml-0 ml-sm-3 mr-3 mr-sm-0 mt-3 mt-md-0"
            title="Refrescar lista de artículos"
            :loading="load"
            :disabled="!(control_copy.estado == 2 || control_copy.estado == 3)"
            :icon="!$vuetify.breakpoint.xs"
            small
            @click="refrescar()"
          >
            <v-icon :left="$vuetify.breakpoint.xs" small>fas fa-sync</v-icon>
            {{ $vuetify.breakpoint.xs ? 'Refrescar' : '' }}
          </v-btn>
          </template>
          <v-spacer v-else></v-spacer>
          <!-- botones fijos -->
          <v-btn
            v-if="existe"
            color="info"
            class="mt-3 mt-md-0"
            :class="!(control_copy.estado == 1 || control_copy.estado == 2 || control_copy.estado == 3) ? 'mr-3' : ''"
            :title="agrupado ? 'Desagrupar categorías' : 'Agrupar por categoría'"
            :disabled="control_copy.estado == 1"
            :icon="!$vuetify.breakpoint.xs && (control_copy.estado == 1 || control_copy.estado == 2 || control_copy.estado == 3)"
            small
            @click="agrupar()"
          >
            <v-icon
              :left="$vuetify.breakpoint.xs || !(control_copy.estado == 1 || control_copy.estado == 2 || control_copy.estado == 3)"
              small
            >
              {{ agrupado ? 'fas fa-list' : 'fas fa-th-list' }}
            </v-icon>
            {{ $vuetify.breakpoint.xs || !(control_copy.estado == 1 || control_copy.estado == 2 || control_copy.estado == 3) ? agrupado ? 'Desagrupar categorías' : 'Agrupar por categoría' : '' }}
          </v-btn>
          <!-- solo muestra el boton de agrupar por art/serie cuando es por series y estado distinto a pendiente/en curso/guardado -->
          <v-btn
            v-if="control_copy.serie == 1 && !(control_copy.estado == 1 || control_copy.estado == 2 || control_copy.estado == 3)"
            class="mt-3 mt-md-0"
            :color="agrupa_serie ? 'pink accent-1' : 'cyan'"
            small
            dark
            @click="agrupar_serie()"
          >
            <v-icon
              small
              left
            >
              {{ agrupa_serie ? 'fas fa-boxes' : 'fas fa-layer-group' }}
            </v-icon>
            {{ agrupa_serie ? 'Agrupar por artículos' : 'Agrupar por serie' }}
          </v-btn>
          <!-- solo muestra el boton mostrar select cuando es por articulo y esta el estado lo permita -->
          <v-btn
            v-if="permiso == 1 && control_copy.serie == 0 && estados_control_stk.find(e => e.codigo == control_copy.estado).inventario"
            class="mt-3 mt-md-0"
            color="indigo"
            small
            dark
            @click="check = true"
          >
            <v-icon small left>fas fa-tasks</v-icon>
            Ajustar
          </v-btn>
        </v-card-title>
        <v-card-text v-if="existe" class="pa-0">
          <v-data-table
            v-if="serie && agrupa_serie"
            class="cebra"
            :group-by="agrupado ? 'categoria' : []"
            :loading="load"
            :items="series"
            :headers="headers_serie"
            :search="search"
            :footer-props="{'items-per-page-options':[50],'show-current-page':true, 'show-first-last-page':true}"
            :sort-by="agrupado ? 'articulo' : 'estado'"
            :sort-desc="!agrupado"
            dense
          >
            <template v-slot:[`group.header`]="{ group, headers, isOpen, toggle }">
              <th :colspan="headers.length" class="body-1" style="background: var(--v-accent-base)">
                <v-btn @click="toggle" icon :ref="group" :data-open="isOpen">
                  <v-icon>{{ isOpen ? 'fas fa-chevron-up' : 'fas fa-chevron-down' }}</v-icon>
                </v-btn>
                {{ group }}
              </th>
            </template>
            <template v-slot:[`item.observacion`]="{ item }">
              <v-text-field
                v-model.trim="item.observacion"
                class="pb-2"
                :title="item.observacion"
                :disabled="readonly || item.estado == 1"
                hide-details
                dense
                @blur="guardar_comentario_serie(item)"
              ></v-text-field>
            </template>
            <template v-slot:[`item.estado`]="{ item }">
              <v-chip
                :color="estados_control_stk_det.find(e => e.id == item.estado).color"
                small
              >
                {{ estados_control_stk_det.find(e => e.id == item.estado).nombre }}
              </v-chip>
            </template>
            <template v-slot:no-data>
              <v-alert
                v-if="control_copy.estado == 1"
                class="mx-auto mt-4"
                max-width="500"
                type="warning"
                border="left"
                dense
                text
              >
                Inicie el control de stock para ver los articulos
              </v-alert>
            </template>
          </v-data-table>
          <v-data-table
            v-else
            v-model="selected"
            class="cebra"
            id="id"
            selectable-key="inventario"
            :show-select="check"
            :sort-by="agrupado ? 'articulo' : 'estado'"
            :sort-desc="!agrupado"
            :group-by="agrupado ? 'categoria' : []"
            :loading="load"
            :items="articulos"
            :headers="headers_articulo"
            :search="search"
            :footer-props="{'items-per-page-options':[50],'show-current-page':true, 'show-first-last-page':true}"
            dense
            @toggle-select-all="selectAllToggle"
          >
            <template v-slot:[`group.header`]="{ group, headers, isOpen, toggle }">
              <th :colspan="headers.length" class="body-1" style="background: var(--v-accent-base)">
                <v-btn @click="toggle" icon :ref="group" :data-open="isOpen">
                  <v-icon>{{ isOpen ? 'fas fa-chevron-up' : 'fas fa-chevron-down' }}</v-icon>
                </v-btn>
                {{ group }}
              </th>
            </template>
            <template v-slot:[`item.stock_real`]="{ item }">
              <v-text-field
                v-model="item.stock_real"
                type="number"
                class="pb-2"
                :ref="`dtStk${item.articulo}`"
                :disabled="readonly"
                hide-details
                dense
                @blur="validar_stock(item)"
              ></v-text-field>
            </template>
            <template v-slot:[`item.observacion`]="{ item }">
              <v-text-field
                v-model.trim="item.observacion"
                class="pb-2"
                :title="item.observacion"
                :ref="`dtCom${item.articulo}`"
                :disabled="readonly"
                hide-details
                dense
                @blur="guardar_comentario(item)"
              ></v-text-field>
            </template>
            <template v-slot:[`item.estado`]="{ item }">
              <v-chip
                :color="estados_control_stk_det.find(e => e.id == item.estado).color"
                small
              >
                {{ estados_control_stk_det.find(e => e.id == item.estado).nombre }}
              </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                color="indigo"
                title="Historial"
                class="mr-2"
                :disabled="item.estado == 1"
                small
                @click="ver_historial(item)"
              >
                fas fa-history
              </v-icon>
              <v-icon
                v-if="(control_copy.estado == 5 || control_copy.estado == 7) && permiso == 1"
                :color="item.recupero ? estados_control_stk.find(e => e.codigo == control_copy.estado).color : ''"
                :title="control_copy.estado == 5 ? permiso_sup ? 'Auditar' : 'Ver auditado' : 'Preauditar'"
                small
                @click="auditar(item)"
              >
                fas fa-clipboard-check
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-alert
                v-if="control_copy.estado == 1"
                class="mx-auto mt-4"
                max-width="500"
                type="warning"
                border="left"
                dense
                text
              >
                Inicie el control de stock para ver los articulos
              </v-alert>
            </template>
          </v-data-table>
          <div :class="selected.length > 0 ? 'ml-2 pb-1 mt-n2' : ''">
            <SelectedCounter
              :selected.sync="selected"
              :items.sync="articulos"
              :custom="true"
              @criterio="select_all()"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- DATA TABLE DE INEXISTENTES -->
    <v-col cols="12" class="px-0">
      <v-simple-table
        v-if="inexistentes.length > 0"
        class="elevation-2 mt-4"
        dense
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left text-h6 red--text">{{ serie ? 'Series' : 'Artículos' }} inexistentes ingresad{{ serie ? 'a' : 'o' }}s</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in inexistentes"
              :key="index"
            >
              <td>{{ item }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <!-- ACCIONES -->
    <v-row class="d-flex justify-end pr-3 my-3">
      <template v-if="existe">
        <v-btn
          v-if="permiso == 1 && control_copy.serie == 0 && estados_control_stk.find(e => e.codigo == control_copy.estado).inventario"
          class="ml-3 mt-2"
          color="indigo"
          :disabled="selected.length < 1"
          :dark="selected.length > 0"
          @click="crear_inventario()"
        >
          <v-icon left small>fas fa-sliders-h</v-icon>
          Generar ajuste
        </v-btn>
        <v-btn
          v-if="control_copy.estado == 4 || control_copy.estado == 5 || control_copy.estado == 7"
          class="ml-3 mt-2"
          color="info"
          @click="descargar_informe()"
        >
          <v-icon left small>fas fa-print</v-icon>
          Informe
        </v-btn>
        <BtnConfirmar
          v-if="(control_copy.estado == 1 && permiso == 1) || cancelar == 1"
          clase="ml-3 mt-2"
          nombre="Inhabilitar"
          icono="fas fa-ban"
          :color="estados_control_stk.find(e => e.codigo == 6).color"
          :texto="`¿Desea <strong>inhabilitar</strong> el control de stock <strong>${control_copy.descripcion}</strong>?`"
          :dark="true"
          @action="inhabilitar_control()"
        />
        <BtnConfirmar
          v-if="control_copy.estado == 3 || (control_copy.estado == 2 && readonly)"
          clase="ml-3 mt-2"
          nombre="Continuar"
          color="primary"
          icono="fas fa-edit"
          :texto="`¿Desea <strong>continuar</strong> con el control de stock?`"
          @action="continuar_control()"
        />
        <BtnConfirmar
          v-if="control_copy.estado == 3 || control_copy.estado == 2"
          clase="ml-3 mt-2"
          nombre="Finalizar"
          color="success"
          icono="fas fa-flag-checkered"
          :texto="`¿Desea <strong>finalizar</strong> el control de stock?`"
          @action="finalizar_control()"
        />
        <BtnConfirmar
          v-if="control_copy.estado == 2 && !readonly"
          clase="ml-3 mt-2"
          color="orange"
          :texto="`¿Desea <strong>guardar</strong> el control de stock?`"
          :dark="true"
          @action="guardar_control()"
        />
        <BtnConfirmar
          v-if="control_copy.estado == 1"
          clase="ml-3 mt-2"
          nombre="Iniciar"
          icono="fas fa-arrow-alt-circle-right"
          :texto="`¿Desea <strong>iniciar</strong> el control de stock?`"
          @action="iniciar_control()"
        />
      </template>
      <v-btn
        v-if="control_copy.estado != 2"
        class="ml-3 mt-2"
        :color="editar ? 'error' : 'secondary'"
        @click="cerrar()"
      >
        {{ editar ? 'Cancelar' : 'Volver' }}
      </v-btn>
      <BtnConfirmar
        v-if="editar"
        clase="ml-3 mt-2"
        @action="guardar_detalle()"
      />
    </v-row>
    <!-- COMPONENTES -->
    <ControlStockArchivo
      v-model="dialog_archivo"
      :p_id="parseInt(id)"
      @procesar="procesar_series_archivo"
    />
    <ControlStockAsistido
      v-model="dialog_asistido"
      :p_id="parseInt(id)"
      @procesar="procesar_series"
    />
    <PDFViewer
      v-model="dialog_pdf"
      :pdf="pdf"
      :nombre="pdf_nombre"
      :titulo="pdf_nombre"
    />
    <AgregarArtSobrante
      v-model="dialog_sobrante"
      :p_id="parseInt(id)"
      :p_articulos.sync="articulos"
    />
    <ArticulosAsistido
      v-model="dialog_asistido_art"
      :p_id="parseInt(id)"
      :p_permiso="permiso"
      :p_articulos.sync="articulos"
      :p_inexistentes.sync="inexistentes"
    />
    <HistorialUbicaciones
      v-model="dialog_historial"
      :p_id="parseInt(id)"
      :p_articulo="articulo"
      :p_nombre="nombre"
    />
    <AuditarArticulo
      v-model="dialog_auditar"
      :p_permiso="permiso_sup"
      :p_articulo="item"
      :p_estado="control_copy.estado"
      @actualizar="actualizar_precio"
      @color="cambiar_color"
    />
    <DetalleInventario
      v-model="dialog_inventario"
      :codigo="null"
      :control="control_inventario"
      @nuevo="nuevo_inventario"
    />
    <!-- @nuevo="agrupar(); refrescar(); selected = []; check = false" -->
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { order_list_by, valdiar_rango_fecha } from '../../util/utils'
import { reporteControlStock, planillaArtStock } from '../../util/plantillas/pdfs'
import FechaPicker from '../../components/util/FechaPicker'
import HoraPicker from '../../components/util/HoraPicker'
import ArticulosActivos from '../../components/util/ArticulosActivos'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import SearchDataTable from '../../components/util/SearchDataTable'
import ControlStockArchivo from '../../components/stock/ControlStockArchivo'
import ControlStockAsistido from '../../components/stock/ControlStockAsistido'
import PDFViewer from '../../util/plantillas/PDFViewer'
import AgregarArtSobrante from '../../components/stock/AgregarArtSobrante'
import ArticulosAsistido from '../../components/stock/ArticulosAsistido'
import HistorialUbicaciones from '../../components/stock/HistorialUbicaciones'
import AuditarArticulo from '../../components/stock/AuditarArticulo'
import SelectedCounter from '../../components/util/SelectedCounter'
import DetalleInventario from '../../components/auditoria/DetalleInventario'

export default {
  data () {
    return {
      id: this.$route.params.id,
      panel: 1,
      permiso: 0,
      cancelar: 0,
      permiso_sup: 0,
      search: '',
      pdf_nombre: '',
      articulo: null,
      nombre: null,
      pdf: null,
      load: false,
      serie: false,
      check: false,
      existe: false,
      editar: false,
      readonly: true,
      agrupado: true,
      permite_art: false,
      agrupa_serie: false,
      dialog_pdf: false,
      dialog_auditar: false,
      dialog_archivo: false,
      dialog_asistido: false,
      dialog_sobrante: false,
      dialog_historial: false,
      dialog_inventario: false,
      bloquear_asistido: false,
      dialog_asistido_art: false,
      categorias_filtradas: [],
      inexistentes: [],
      articulos: [],
      selected: [],
      series: [],
      headers_articulo: [
        { text: 'Cod. Interno', value: 'articulo', align: 'end' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Stock virtual', value: 'stock_virtual', align: 'end', sortable: false },
        { text: 'Stock real', value: 'stock_real', align: 'end', width: '75', sortable: false },
        { text: 'Observaciones', value: 'observacion', sortable: false },
        { text: 'Estado', value: 'estado' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false, align: 'center' }
      ],
      headers_serie: [
        { text: 'Cod. Interno', value: 'articulo', align: 'end' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Serie', value: 'serie', sortable: false },
        { text: 'Observaciones', value: 'observacion', sortable: false },
        { text: 'Estado', value: 'estado' }
      ],
      item: {},
      control: {},
      control_copy: {},
      control_inventario: {},
      rules: {
        required: value => !!value || 'Campo requerido',
      }
    }
  },
  async created () {
    window.addEventListener('beforeunload', this.onClose)
    await this.get_datos()
    await this.get_permiso()
    await this.get_control()
    this.cancelar = await this.$store.dispatch('controlStock/puede_cancelar')
    // contrae todos los grupos de categorias
    Object.keys(this.$refs).forEach(k => {
      if (this.$refs[k] && this.$refs[k].$attrs['data-open']) {
        this.$refs[k].$el.click()
      }
    })
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.onClose)
  },
  computed: {
    ...mapState('rubros', ['rubros']),
    ...mapState('bodegas', ['bodegas_user']),
    ...mapState('categorias', ['categorias']),
    ...mapState('controlStock', ['estados_control_stk', 'estados_control_stk_det'])
  },
  components: {
    FechaPicker,
    HoraPicker,
    ArticulosActivos,
    BtnConfirmar,
    SearchDataTable,
    ControlStockArchivo,
    ControlStockAsistido,
    PDFViewer,
    AgregarArtSobrante,
    ArticulosAsistido,
    HistorialUbicaciones,
    AuditarArticulo,
    SelectedCounter,
    DetalleInventario
  },
  watch: {
    async editar (val) {
      if (val) {
        this.$refs.form.resetValidation()
      } else {
        // vuelve a dejar los datos del control como estaban cuando hizo la consulta
        this.control = await JSON.parse(JSON.stringify(this.control_copy))
        this.get_categorias()
      }
    },
    serie (val) {
      this.agrupa_serie = val
    }
  },
  methods: {
    nuevo_inventario (codigo) {
      this.$swal.fire({
        icon: 'success',
        title: `Se creo el inventario Nº ${codigo}`
      })
      this.agrupar()
      this.refrescar()
      this.selected = []
      this.check = false
    },
    cambiar_color (id, diferencia) {
      let articulo = this.articulos.find(a => a.id == id)
      if (articulo) {
        articulo.recupero = true
        if (diferencia !== null) {
          articulo.diferencia = parseInt(diferencia)
        }
      }
    },
    actualizar_precio (id, precio) {
      let articulo = this.articulos.find(a => a.id == id)
      if (articulo) {
        articulo.precio = precio
      }
    },
    crear_inventario () {
      const empresa = this.bodegas_user.find(b => b.codigo == this.control_copy.deposito).empresa_codigo
      const sucursal = this.bodegas_user.find(b => b.codigo == this.control_copy.deposito).sucursal_codigo
      let articulos = []
      for (const art of this.selected) {
        // si el articulo vino con diferencia tomamos esa, sino se calcula
        let diferencia = null
        if (art.diferencia !== null) {
          diferencia = art.diferencia
        } else {
          diferencia = parseInt(art.stock_real) - parseInt(art.stock_virtual)
        }
        articulos.push({
          codigo: art.articulo,
          nombre: art.nombre,
          stock_virtual: art.stock_virtual,
          stock_real: art.stock_virtual + diferencia,
          diferencia: diferencia,
          solicita_serie: art.solicita_serie,
          series: [],
          series_ok: (parseInt(diferencia) < 0 && art.solicita_serie == 1) ? 0 : 1
        })
      }
      this.control_inventario = {
        id: this.control_copy.id,
        empresa: empresa,
        sucursal: sucursal,
        deposito: this.control_copy.deposito,
        referencia: 'Ajuste por Control de Stock Nº ' + this.control_copy.id + ' - ' + this.control_copy.descripcion,
        articulos: articulos,
        bodegas: this.bodegas_user.filter(b => b.empresa_codigo == empresa && b.sucursal_codigo == sucursal)
      }
      this.dialog_inventario = true
    },
    select_all () {
      this.selected = []
      this.articulos.forEach(art => {
        if (art.inventario) {
          this.selected.push(art)
        }
      })
    },
    selectAllToggle () {
      if (this.selected.length < this.articulos.filter(art => art.inventario).length) this.select_all()
      else this.selected = []
    },
    agrupar_serie () {
      this.serie = !this.serie
      this.agrupa_serie = true
    },
    auditar (item) {
      this.item = JSON.parse(JSON.stringify(item))
      this.dialog_auditar = true
    },
    async ver_historial (item) {
      this.articulo = item.articulo
      this.nombre = item.nombre
      this.dialog_historial = true
    },
    agrupar () {
      this.agrupado = !this.agrupado
      if (this.agrupado) {
        this.headers_articulo.splice(2, 1)
        this.headers_serie.splice(2, 1)
      } else {
        const header = { text: 'Categoría', value: 'categoria' }
        this.headers_articulo.splice(2, 0, header)
        this.headers_serie.splice(2, 0, header)
      }
    },
    async refrescar () {
      this.load = true
      this.articulos = []
      this.series = []
      await this.obtener_articulos()
      this.load = false
    },
    async descargar_modelo () {
      this.$store.state.loading = true
      let articulos = []
      this.articulos.forEach(art => {
        articulos.push({
          codigo: art.articulo,
          articulo: art.nombre,
          categoria: art.categoria,
          stock_virtual: art.stock_virtual.toString(),
          stock_real: ' '
        })
      })
      order_list_by(articulos, 'categoria')
      await planillaArtStock(articulos, this.id, this.control_copy.deposito_nombre)
      this.$store.state.loading = false
    },
    async descargar_informe () {
      this.$store.state.loading = true
      await this.$store.dispatch('controlStock/get_datos_informe', this.id)
        .then(async (res) => {
          this.pdf = await reporteControlStock(res.data)
          this.pdf_nombre = `Control de Stock Nº ${this.id}`
          this.dialog_pdf = true
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    procesar_series (correctas, sobrantes, inexistentes) {
      // actualiza el estado de las series existentes
      correctas.forEach(correcta => {
        let serie = this.series.find(s => s.serie.toString().toUpperCase() == correcta.serie.toString().toUpperCase())
        if (serie) {
          serie.estado = correcta.estado
        }
      })
      // agrega las series sobrantes al array de series
      sobrantes.forEach(sobrante => {
        this.series.push(sobrante)
      })
      // actualiza el estado de las series pendientes a faltante
      this.series.forEach(serie => {
        if (serie.estado == 1) {
          serie.estado = 4
        }
      })
      // copia las series inexistentes
      this.inexistentes = inexistentes
    },
    procesar_series_archivo (correctas, sobrantes, inexistentes) {
      this.procesar_series(correctas, sobrantes, inexistentes)
      // siempre que suba un archivo de manera correcta entra a esta funcion, por lo tanto aqui bloquea la funcion de asistido
      this.bloquear_asistido = true
    },
    async validar_stock (item) {
      if (item.stock_real) {
        this.$store.state.loading = true
        await this.$store.dispatch('controlStock/inserat_stock', {
          id: this.id,
          stock_real: item.stock_real,
          articulo: item.articulo,
          deposito: this.control_copy.deposito
        })
          .then((res) => {
            // actualiza el articulo
            item.stock_virtual = res.stock
            item.estado = res.estado
            // setea el foco en el input del comentario
            this.$refs[`dtCom${item.articulo}`].focus()
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
            item.stock_real = null
            this.$refs[`dtStk${item.articulo}`].focus()
          })
        this.$store.state.loading = false
      }
    },
    async guardar_comentario_serie (item) {
      if (item.observacion != null || item.observacion != undefined) {
        this.$store.state.loading = true
        await this.$store.dispatch('controlStock/insertar_comentario_serie', {
          id: this.id,
          comentario: item.observacion,
          serie: item.serie,
          articulo: item.articulo
        })
          .then(() => {
            // todo ok
            if (item.observacion == '') {
              item.observacion = null
            }
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },
    async guardar_comentario (item) {
      if (item.observacion != null || item.observacion != undefined) {
        this.$store.state.loading = true
        await this.$store.dispatch('controlStock/insertar_comentario', {
          id: this.id,
          comentario: item.observacion,
          articulo: item.articulo
        })
          .then(() => {
            // todo ok
            if (item.observacion == '') {
              item.observacion = null
            }
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },
    async finalizar_control () {
      this.$store.state.loading = true
      await this.$store.dispatch('controlStock/finalizar_control', this.id)
        .then(async (res) => {
          this.readonly = true
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          // actualiza el estado del control
          this.control.estado = res.data.estado
          this.control.estado_nombre = this.estados_control_stk.find(e => e.codigo == this.control.estado).nombre
          this.control.usuario_fin = res.data.usuario
          this.control.fecha_fin = res.data.fecha
          this.control_copy = JSON.parse(JSON.stringify(this.control))
          // muestra el modal para descargar el pdf
          this.descargar_informe()
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async guardar_control () {
      this.$store.state.loading = true
      await this.$store.dispatch('controlStock/guardar_control', this.id)
        .then(async (res) => {
          this.readonly = true
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          // actualiza el estado del control
          this.control.estado = res.estado
          this.control.estado_nombre = this.estados_control_stk.find(e => e.codigo == this.control.estado).nombre
          this.control_copy = JSON.parse(JSON.stringify(this.control))
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async continuar_control () {
      this.$store.state.loading = true
      await this.$store.dispatch('controlStock/verificar_curso', this.id)
        .then(async (res) => {
          let confirma = true

          if (res.data.en_curso == 1) {
            // espera que responda al mensaje de advertencia
            await this.$swal.fire({
              icon: 'warning',
              title: `El usuario ${res.data.usuario} se encuentra realizando el control de stock en este momento ¿Desea continuar?`,
              confirmButtonText: 'Sí',
              cancelButtonText: 'No',
              showCancelButton: true,
              focusCancel: true
            })
              .then((res) => {
                // si pone que no o hace click fuera del modal no hace nada
                confirma = res.isConfirmed
              })
          }
          
          if (confirma) {
            // actualiza el estado a 2 (en curso)
            await this.$store.dispatch('controlStock/continuar_control', this.id)
              .then((res) => {
                this.readonly = false
                // actualiza el estado del control
                this.control.estado = res.estado
                this.control.estado_nombre = this.estados_control_stk.find(e => e.codigo == this.control.estado).nombre
                this.control_copy = JSON.parse(JSON.stringify(this.control))
                // expande todos los grupos de categorias (en caso de estar agrupados)
                if (this.agrupado) {
                  Object.keys(this.$refs).forEach(k => {
                    if (this.$refs[k] && !this.$refs[k].$attrs['data-open']) {
                      this.$refs[k].$el.click()
                    }
                  })
                }
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
          }

        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async iniciar_control () {
      this.$store.state.loading = true
      await this.$store.dispatch('controlStock/iniciar_control', this.id)
        .then(async (res) => {
          this.readonly = false

          // actualiza el estado del control
          this.control.estado = res.data.estado
          this.control.estado_nombre = this.estados_control_stk.find(e => e.codigo == this.control.estado).nombre
          this.control.usuario_inicio = res.data.usuario
          this.control.fecha_inicio = res.data.fecha
          this.control_copy = JSON.parse(JSON.stringify(this.control))

          // obtiene los articulos/series
          if (this.serie) {
            await this.$store.dispatch('controlStock/obtener_series', this.id)
              .then((data) => {
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success'
                })
                this.series = data.series
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
          } else {
            await this.$store.dispatch('controlStock/obtener_articulos', this.id)
              .then((data) => {
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success'
                })
                this.articulos = data.articulos
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
          }

        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async guardar_detalle () {
      if (this.$refs.form.validate()) {
        ///  VALIDACIONES  ///

        if (!valdiar_rango_fecha(this.control.fecha_hasta, this.control.fecha_desde)) {
          this.$store.dispatch('show_snackbar', {
            text: 'La fecha desde no puede ser mayor que la fecha hasta',
            color: 'warning'
          })
          return
        }
        if (this.control.hora_desde >= this.control.hora_hasta) {
          this.$store.dispatch('show_snackbar', {
            text: 'La hora de inicio no puede ser mayor o igual que la hora de finalizacion',
            color: 'warning'
          })
          return
        }
        if (this.control.articulos.length == 0 && this.control.categorias.length == 0)  {
          this.$store.dispatch('show_snackbar', {
            text: 'Debe seleccionar al menos una categoria o un articulo',
            color: 'warning'
          })
          return
        }

        ///  CONSULTA  ///

        // solo si cambio algun valor actualiza
        if (JSON.stringify(this.control) != JSON.stringify(this.control_copy)) {

          // variables para determinar si modifico o no un array
          let cat = 0
          let art = 0

          // verifica si modifico alguno de los array
          if (this.modifico_array('categorias')) cat = 1
          if (this.modifico_array('articulos')) art = 1

          // crea la data para enviar
          let data = await JSON.parse(JSON.stringify(this.control))
          // formatea el array de articulos para dejar solo los codigos
          let articulos = []
          this.control.articulos.forEach(articulo => {
            articulos.push(articulo.codigo)
          })
          data.articulos = articulos

          data.cat = cat
          data.art = art

          this.$store.state.loading = true
          await this.$store.dispatch('controlStock/editar_control', data)
            .then((res) => {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success'
              })
              // actualiza los datos del control en la vista y bloquea la edicion
              this.control_copy = JSON.parse(JSON.stringify(this.control))
              this.editar = false
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.$store.state.loading = false

        } else {
          this.$store.dispatch('show_snackbar', {
            text: 'No se realizaron cambios en el control de stock: los campos nuevos son iguales a los anteriores',
            color: 'warning',
            timeout: 4000
          })
        }

      }
    },
    async inhabilitar_control () {
      this.$store.state.loading = true
      await this.$store.dispatch('controlStock/inhabilitar_control', this.id)
        .then((res) => {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          // actualiza el estado del control
          this.control.estado = res.estado
          this.control.estado_nombre = this.estados_control_stk.find(e => e.codigo == this.control.estado).nombre
          this.control_copy = JSON.parse(JSON.stringify(this.control))
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async get_control () {
      this.$store.state.loading = true
      await this.$store.dispatch('controlStock/get_control', this.id)
        .then(async (res) => {
          this.existe = true
          this.control = await res.data
          this.get_categorias()
          // hace una copia del control
          this.control_copy = await JSON.parse(JSON.stringify(this.control))

          // si existe el nombre del archivo es pq ya se subio uno y bloquea el asistido
          if (this.control_copy.archivo) {
            this.bloquear_asistido = true
          }

          // verifica si tiene serie o no
          if (this.control_copy.serie == 1) {
            this.serie = true
            // si el estado es distinto de en curso o guardado elimina las series del local storage en caso de haber quedado
            if (this.control_copy.estado != 2 && this.control_copy.estado != 3) {
              localStorage.removeItem(`css${this.id}`)
              localStorage.removeItem(`csa${this.id}`)
              localStorage.removeItem(`ubi${this.id}`)
            }
          }

          // si el control es por categoria permite agregar articulos
          if (this.control_copy.categorias.length > 0) {
            this.permite_art = true
          }

          // si el estado es distinto de 1 (pendiente) trae los articulos/series
          if (this.control_copy.estado != 1) {
            await this.obtener_articulos()
          }

          // si el estado es 5 (auditado) verifica si tiene permiso de modificacion
          if (this.control_copy.estado == 5) {
            let resp = await this.$store.dispatch('controlStock/verify_permiso_sup')
            this.permiso_sup = resp.permiso
          }

        })
        .catch((error) => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async obtener_articulos () {
      const estado = this.control_copy.estado
      // si el control tiene estado finalizado auditado o preauditado muestra todo desagrupado
      if (estado == 4 || estado == 5 || estado == 7) {
        this.agrupar()
      }
      if (this.serie) {
        await this.$store.dispatch('controlStock/obtener_series', this.id)
          .then((res) => {
            this.series = res.series
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
      } 
      if (!this.serie || (this.serie && (estado == 4 || estado == 5 || estado == 7))) {
        await this.$store.dispatch('controlStock/obtener_articulos', this.id)
          .then((res) => {
            this.articulos = res.articulos
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
      }
    },
    async get_datos () {
      this.$store.state.loading = true
      await this.$store.dispatch('rubros/get_rubros_habilitados')
      await this.$store.dispatch('categorias/get_categorias_habilitadas')
      await this.$store.dispatch('controlStock/get_estados')
      await this.$store.dispatch('controlStock/get_estados_detalle')
      this.$store.state.loading = false
    },
    async get_permiso () {
      this.$store.state.loading = true
      let res = await this.$store.dispatch('controlStock/verify_permiso')
      this.permiso = res.permiso
      this.$store.state.loading = false
    },
    get_categorias () {
      this.categorias_filtradas = []
      this.control.rubros.forEach(rubro => {
        let categorias = this.categorias.filter(cat => cat.rubro_codigo == rubro)
        categorias.forEach(categoria => {
          this.categorias_filtradas.push(categoria)
        })
      })
      order_list_by(this.categorias_filtradas, 'nombre')
    },
    modifico_array (valor) {
      return JSON.stringify(this.control[valor].sort()) != JSON.stringify(this.control_copy[valor].sort())
    },
    onClose(event) {
      if (this.control_copy.estado == 2 && !this.readonly) {
        event.preventDefault()
        event.returnValue = ""
      }
    },
    cerrar () {
      window.close()
    }
  }
}
</script>

<style>
.v-data-footer {
  justify-content: right !important;
}
</style>