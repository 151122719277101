<template>
  <v-dialog
    v-model="dialog"
    width="600"
  >
    <v-card>
      <v-card-title>
        Seleccione un artículo
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="px-0">
        <v-data-table
          v-model="selected"
          sort-by="nombre"
          :items="p_coincidencias"
          :headers="[
            { text: 'Cod. Interno', value: 'codigo', align: 'end' },
            { text: 'Nombre', value: 'nombre' }
          ]"
          :items-per-page="-1"
          hide-default-footer
          single-select
          show-select
          dense
        ></v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          class="ml-3"
          :disabled="selected.length != 1"
          @click="seleccionar()"
        >
          <v-icon left>fas fa-check</v-icon>
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      selected: []
    }
  },
  props: {
    value: Boolean,
    p_coincidencias: Array
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    dialog (val) {
      val || (this.selected = [])
    }
  },
  methods: {
    seleccionar () {
      this.$emit('asociar', this.selected[0])
      this.dialog = false
    }
  }
}
</script>