<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
    scrollable
  >
    <v-card>
      <v-card-title>
        Escanear códigos
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-title class="pt-0">
        <form
          :style="$vuetify.breakpoint.smAndUp ? 'width: 40%' : 'width: 50%'"
          @submit.prevent="agregar_codigo()"
        >
          <v-text-field
            v-model="codigo"
            label="Código"
            :type="barra ? 'tel' : 'number'"
            hide-details
            autofocus
            outlined
            dense
            ref="codigo"
          ></v-text-field>
        </form>
        <v-btn
          v-if="$device.android || $device.ios"
          color="indigo"
          class="ml-3"
          small
          dark
          @click="dialog_camera = true"
        >
          <v-icon left small> fas fa-camera</v-icon>
          Cámara
        </v-btn>
        <v-spacer></v-spacer>
        <v-switch
          v-model="barra"
          label="Cod. Barra"
          :class="$device.android || $device.ios ? 'mt-3' : 'mt-n1'"
          hide-details
          @change="codigo = ''; $refs.codigo.focus()"
        ></v-switch>
      </v-card-title>
      <v-card-text class="py-0">
        <v-simple-table
          :key="tableKey"
          dense
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Cod. Interno</th>
                <th class="text-left">Cod. Barra</th>
                <th class="text-right" style="width: 0%">Cantidad</th>
                <th v-if="p_permiso == 1" class="text-center">Categoria</th>
                <th class="text-right">Quitar</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in codigos"
                :key="index"
              >
                <td>{{ item.codigo }}</td>
                <td>{{ item.cod_barra }}</td>
                <td class="d-flex justify-end">
                  <v-text-field
                    v-model="item.cantidad"
                    style="width: 50%"
                    type="number"
                    hide-details
                    dense
                    @blur="depurar(item.cantidad, index)"
                  ></v-text-field>
                </td>
                <td v-if="p_permiso == 1" class="text-center">
                  <v-chip
                    :color="p_articulos.find(a => a.articulo == item.codigo) ? '#0000' : 'orange'"
                    small
                  >
                    {{ p_articulos.find(a => a.articulo == item.codigo) ? p_articulos.find(a => a.articulo == item.codigo).categoria : 'N/A' }}
                  </v-chip>
                </td>
                <td class="text-right">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-if="item.advertencia != null"
                        v-on="on"
                        color="warning"
                        class="mr-3"
                        small
                        left
                        @click="item.coincidencias ? mostrar_coincidencias(item) : quitar_codigo(index)"
                      >
                        fas fa-exclamation-triangle
                      </v-icon>
                    </template>
                    <span>{{ item.advertencia }}</span>
                  </v-tooltip>
                  <v-icon
                    color="error"
                    class="mr-3"
                    small
                    left
                    @click="quitar_codigo(index)"
                  >
                    fas fa-times
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4 mt-2">
        <v-row class="d-flex justify-end align-center pa-3">
          <v-text-field
            v-model="ubicacion"
            label="Ubicación"
            class="mt-2"
            hide-details
            outlined
            dense
            @blur="guardar_ubicacion()"
          ></v-text-field>
          <v-btn
            color="error"
            class="mt-2 ml-sm-2 ml-10"
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="info"
            class="mt-2 ml-sm-2 ml-10"
            :disabled="codigos.filter(c => c.codigo == null).length == 0"
            @click="validar_codigos()"
          >
            <v-icon left>fas fa-check</v-icon>
            Validar codigos
          </v-btn>
          <BtnConfirmar
            clase="mt-2 ml-2"
            nombre="Confirmar"
            :disabled="codigos.length < 1 || codigos.filter(c => c.codigo == null).length > 0"
            @action="procesar()"
          />
        </v-row>
      </v-card-actions>
    </v-card>
    <LectorCodigos
      v-model="dialog_camera"
      :key="componentKey"
      @result="agregar"
    />
    <!-- modal coincidencias -->
    <ModalCoincidenciasCodBarra
      v-model="dialog_resolver"
      :p_coincidencias="item.coincidencias"
      @asociar="asociar_codigo"
    />
  </v-dialog>
</template>

<script>
/**
 * Modal para pistolear codigos de articulos
 * 
 *  Nota: todos los props son obligatorios
 * 
 */
import BtnConfirmar from '../util/BtnConfirmar'
import LectorCodigos from '../util/LectorCodigos'
import ModalCoincidenciasCodBarra from './ModalCoincidenciasCodBarra.vue'

export default {
  data () {
    return {
      barra: false,
      dialog_camera: false,
      dialog_resolver: false,
      componentKey: 0,
      tableKey: 0,
      codigo: '',
      ubicacion: 'SECTOR ',
      item: {},
      codigos: []
    }
  },
  props: {
    value: Boolean,
    p_id: Number,         // id del control de stock
    p_permiso: Number,    // permiso para ciertos roles (1 o 0)
    p_articulos: Array,   // array de todos los articulos del control de stock (enviar sincronizado)
    p_inexistentes: Array // array de todos los articulos del control de stock (enviar sincronizado)
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    articulos: {
      get () {
        return this.p_articulos
      },
      set (value) {
        this.$emit('update:p_articulos', value)
      }
    },
    inexistentes: {
      get () {
        return this.p_inexistentes
      },
      set (value) {
        this.$emit('update:p_inexistentes', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    LectorCodigos,
    ModalCoincidenciasCodBarra
  },
  watch: {
    dialog (val) {
      if (val) {
        // revisa si existen codigos almacenadas para este control en el local storage
        let item = localStorage.getItem(`csa${this.p_id}`)
        if (item) {
          this.codigos = JSON.parse(item)
        }
        item = localStorage.getItem(`ubi${this.p_id}`)
        if (item) {
          this.ubicacion = JSON.parse(item)
        }
      } else {
        this.barra = false
        this.codigo = ''
        this.ubicacion = 'SECTOR '
      }
    },
    dialog_camera (val) {
      val || (this.componentKey += 1)
    }
  },
  methods: {
    asociar_codigo (articulo) {
      // busco a ver si encuentro el mismo con codigo y cod_barra pa' sumarlo
      let art = this.codigos.find(c => c.codigo == articulo.codigo && c.cod_barra == this.item.cod_barra)
      if (art) {
        let cantidad = parseInt(this.item.cantidad) + parseInt(art.cantidad)
        this.codigos.splice(this.codigos.indexOf(art), 1)
        this.codigos.splice(this.codigos.indexOf(this.item), 1)
        this.codigos.unshift({
          codigo: art.codigo,
          cod_barra: art.cod_barra,
          cantidad: cantidad
        })
      } else {
        this.item.codigo = articulo.codigo
        delete this.item.advertencia
        delete this.item.coincidencias
        this.tableKey++
      }
      this.dialog_resolver = false
    },
    mostrar_coincidencias (item) {
      this.item = item
      this.dialog_resolver = true
    },
    async validar_codigos () {
      this.$store.state.loading = true
      let result = await this.$store.dispatch('controlStock/validar_codbarra', this.codigos.map(c => c.cod_barra).filter(cod => cod != null))
      this.$store.state.loading =false

      if (result.exito != 1) {
        return this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }

      for (let articulo of this.codigos.filter(c => c.cod_barra != null && c.codigo == null)) {

        let coincidencias = result.data.filter(art => art.cod_barra === articulo.cod_barra)

        switch (coincidencias.length) {
          case 0:
            articulo.advertencia = 'No se encontró el código de barra en el sistema. Haga click aquí para quitarlo'
            break
          case 1:
            articulo.codigo = coincidencias[0].codigo
            break
          default:
            articulo.advertencia = 'Se encontraron múltiples coincidencias para código de barras. Haga click aquí para resolver'
            articulo.coincidencias = coincidencias
            break
        }
      }
      this.tableKey++
      this.set_localstorage(this.codigos)

    },
    async agregar (res) {
      this.codigo = res
      this.agregar_codigo()
      await new Promise(resolve => setTimeout(resolve, 200))
      await this.$swal.fire({
        icon: 'success',
        title: 'Código escaneado correctamente',
        showConfirmButton: false,
        timer: 1000
      })
      this.dialog_camera = true
    },
    async procesar () {
      this.$store.state.loading = true
      await this.$store.dispatch('controlStock/articulos_asistidos', {
        id: this.p_id,
        ubicacion: this.ubicacion,
        arts: this.codigos
      })
        .then((res) => {
          // agrega los sobrantes
          for (const articulo of res.data.sobrantes) {
            this.articulos.push(articulo)
          }

          // modifica el stock y estados de los articulos existentes
          for (const articulo of res.data.articulos) {
            let art = this.articulos.find(a => a.articulo == articulo.codigo)
            if (art) {
              art.stock_real = articulo.stock_real
              art.stock_virtual = articulo.stock_virtual
              art.estado = articulo.estado
            }
          }
          
          // agrega los inexistentes
          this.inexistentes = res.data.inexistentes

          // muestra un mensaje con los errores (en caso de existir)
          let errores = res.data.errores
          if (errores.length > 0) {
            let html = '<div style="text-align: left">'
            for (const error of errores) {
              html += `<p style="margin-top: 10px">Artículo <strong>${error.articulo}</strong> - ${error.error}</p>`
            }
            html += '</div>'
            this.$swal.fire({
              icon: 'warning',
              title: 'No se pudieron agregar los siguientes artículos ',
              html: html,
              width: '700px'
            })
          }
          this.dialog = false
          // limpia el local storage
          this.codigos = []
          this.ubicacion = null
          this.set_localstorage(this.codigos)
          this.guardar_ubicacion()
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    guardar_ubicacion () {
      const key = `ubi${this.p_id}`
      if (this.ubicacion) {
        localStorage.setItem(key, JSON.stringify(this.ubicacion))
      } else {
        localStorage.removeItem(key)
      }
    },
    depurar (cantidad, index) {
      this.$refs.codigo.focus()
      if (cantidad < 1) {
        this.quitar_codigo(index)
      }
    },
    agregar_codigo () {
      if (this.codigo) {
        let art = null
        if (this.barra) {
          art = this.codigos.find(a => a.cod_barra == this.codigo && a.codigo == null)
        } else {
          art = this.codigos.find(a => a.codigo == this.codigo && a.cod_barra == null)
        }
        let cantidad = 1
        // si el codigo ya existe en el array lo borra y lo ingresa nuevamente con su cantidad +1
        if (art) {
          cantidad += parseInt(art.cantidad)
          this.codigos.splice(this.codigos.indexOf(art), 1)
        }
        this.codigos.unshift({
          codigo: this.barra ? null : this.codigo,
          cod_barra: this.barra ? this.codigo : null,
          cantidad: cantidad
        })
        this.codigo = ''
        // copia el array en el localstorage
        this.set_localstorage(this.codigos)
      }
    },
    quitar_codigo (index) {
      this.$refs.codigo.focus()
      this.codigos.splice(index, 1)
      // copia el array en el localstorage
      this.set_localstorage(this.codigos)
    },
    set_localstorage (array) {
      const key = `csa${this.p_id}`
      // si el array es 0 elimina el item del localstorage
      if (array.length == 0) {
        localStorage.removeItem(key)
      } else {
        localStorage.setItem(key, JSON.stringify(array))
      }
    }
  }
}
</script>