<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="60%"
    >
      <v-card
        flat
      >
        <v-card-title>
          Agregar Cupones a la Promoción {{ promocion_id }}
          <v-spacer></v-spacer>
          <v-btn icon><v-icon @click="activo = false" title="Cerrar">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" sm="9" md="9" class="py-1">
              Promoción
              <v-text-field
                v-model="promocion_nombre"
                outlined
                dense
                type="text"
                hide-details
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="py-1">
              Cantidad Cupones
              <v-text-field
                v-model="cantidad"
                outlined
                dense
                type="number"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end mx-2">
          <v-btn color="warning" @click="activo = false" class="ma-2">Volver</v-btn>
            <BtnConfirmar
              @action="generarCupones()"
              color="success"
              :texto="`¿Esta seguro de generar ${cantidad} cupones para la promoción ${promocion_id}?`"
              nombre="Generar"
            />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BtnConfirmar from '../util/BtnConfirmar.vue'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  computed: {
    activo: {
      get() {
        return this.datos.activo
      },
      set(value) {
        this.$emit('setearModalAddCupones', value)
      }
    },
    promocion_id: {
      get() {
        return this.datos.promocion_id
      }
    },
    promocion_nombre: {
      get() {
        return this.datos.promocion_nombre
      }
    }
  },
  components: {
    BtnConfirmar,
  },
  data() {
    return {
      cantidad: ''
    }
  },
  methods: {
    async generarCupones(){
      if (this.cantidad == null || this.cantidad == undefined || this.cantidad.toString().length == 0 || this.cantidad == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Por favor, ingrese una cantidad válida.',
          color: 'warning',
        })
        this.cantidad = ''
        return
      }
      this.$store.state.loading = true
      let generarPeticion = await this.$store.dispatch('promociones/generarCupones', {
        promocion_id: this.promocion_id,
        cantidad: this.cantidad
      })
      this.$store.state.loading = false
      if (generarPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'Los cupones fuero generados con éxito',
          color: 'success',
        })
        this.$emit('setExitoCupones')
        this.activo = false
      }else{
        this.$store.dispatch('show_snackbar', {
          text: generarPeticion.msj,
          color: 'error',
        })
      }
    },
    limpiarModal(){
      this.cantidad = ''
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.limpiarModal()
    }
  },
}
</script>

<style>

</style>