<template>
  <v-dialog
    v-model="dialog"
    width="800"
    scrollable
  >
    <v-card>
      <v-card-title>
        {{ p_control.archivos ? 'Archivos del' : 'Subir archivos al' }} Control de Stock {{ p_control.id }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <div class="text-h6 ml-5 mt-2">
          Archivos existentes
        </div>
        <v-data-table
          class="elevation-2 mt-2 mx-4"
          :headers="headers"
          :items="archivos"
          :loading="load"
          dense
        >
          <template v-slot:[`item.type`]="{ item }">
            <v-icon
              :color="tipos_archivos.find(a => a.extension == item.type).color"
              style="margin-bottom: 3px"
              small
              left
            >
              {{ tipos_archivos.find(a => a.extension == item.type).icono }}
            </v-icon>
            {{ tipos_archivos.find(a => a.extension == item.type).tipo }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              color="success"
              title="Descargar"
              class="mr-2"
              small
              @click="descargar(item)"
            >
              fas fa-download
            </v-icon>
            <v-icon
              color="info"
              title="Ver"
              class="mr-2"
              small
              @click="ver(item)"
            >
              fas fa-eye
            </v-icon>
            <BtnConfirmar
              v-if="p_permiso == 1"
              icono="fas fa-trash"
              title="Borrar"
              color="error"
              clase="mr-2"
              :texto="`¿Desea eliminar el archivo <strong>${item.name}</strong>?`"
              :icon_button="true"
              :small="true"
              @action="eliminar(item)"
            />
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="500"
              type="warning"
              border="left"
              dense
              text
            >
              No se econtraron archivos para el control de stock
            </v-alert>
          </template>
        </v-data-table>
        <FileDropzone
          :key="refrescar"
          :multiple="true"
          :tipos="['.jpg', '.png', '.webp', '.pdf']"
          :max_size="1"
          :ruta="`/control_stock/${p_control.id}`"
          :alerta="true"
          :accion="true"
          :raiz="1"
          :tabla="3"
          :id="p_control.id"
          @action="agregar_archivo"
        />
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          class="ml-3 mt-2"
          color="error"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
    <PDFViewer
      v-model="dialog_pdf"
      :pdf="pdf"
      :nombre="nombre"
      :titulo="nombre"
    />
    <ImgViewer
      v-model="dialog_img"
      :img="img"
      :nombre="nombre"
    />
  </v-dialog>
</template>

<script>
import { tipos_archivos } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar'
import FileDropzone from '../util/FileDropzone'
import PDFViewer from '../../util/plantillas/PDFViewer'
import ImgViewer from '../util/ImgViewer'

export default {
  data () {
    return {
      tipos_archivos: tipos_archivos,
      refrescar: 0,
      load: false,
      dialog_pdf: false,
      dialog_img: false,
      nombre: '',
      pdf: '',
      img: '',
      archivos: [],
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Tipo', value: 'type' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false, align: 'center' },
      ]
    }
  },
  props: {
    value: Boolean,
    p_permiso: Number,
    p_control: Object
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    FileDropzone,
    PDFViewer,
    ImgViewer
  },
  watch: {
    async dialog (val) {
      if (val) {
        this.load = true
        await this.$store.dispatch('get_all_files', {
          path: `/control_stock/${this.p_control.id}`,
          raiz: 1
        })
          .then(res => {
            this.archivos = res.files
          })
          .catch(error => {
            console.log(error.message)
          })
        this.load = false
      } else {
        this.archivos = []
        this.refrescar ++
      }
    }
  },
  methods: {
    async descargar (item) {
      const routeData = this.$router.resolve({ path: `/descargarArchivo?path=/control_stock/${this.p_control.id}/${item.name}` })
      window.open(routeData.href, '_blank')
    },
    async eliminar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('delete_file', {
        path: `/control_stock/${this.p_control.id}/${item.name}`,
        raiz: 1,
        tabla: 3,
        id: this.p_control.id
      })
        .then((res) => {
          this.archivos.splice(this.archivos.indexOf(item), 1)
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    agregar_archivo (file) {
      this.archivos.push(file)
    },
    ver (item) {
      this.nombre = item.name
      const tipo = tipos_archivos.find(a => a.extension == item.type).tipo
      if (tipo == 'PDF') {
        this.pdf = `data:application/pdf;base64,${item.file}`
        this.dialog_pdf = true
      }
      else if (tipo == 'IMG') {
        this.img = `data:${tipos_archivos.find(a => a.extension == item.type).mime};base64,${item.file}`
        this.dialog_img = true
      }
    }
  }
}
</script>