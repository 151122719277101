<template>
  <v-dialog
    v-model="dialog"
    width="700"
    scrollable
  >
    <v-card :disabled="load">
      <v-card-title>
        Modificar movimiento Nº {{ movimiento.num_mov }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="load_data">
        <v-skeleton-loader
          class="mx-auto"
          type="list-item-three-line"
        ></v-skeleton-loader>
        <v-card outlined>
          <v-skeleton-loader
            class="mx-auto"
            type="list-item-three-line"
          ></v-skeleton-loader>
        </v-card>
      </v-card-text>
      <v-card-text v-else class="pt-8 pb-3">
        <v-form ref="form">
          <v-row class="pb-2">
            <v-col cols="12" sm="4" class="py-0">
              <v-autocomplete
                v-model="movimiento.banco_codigo"
                label="Banco"
                item-text="nombre"
                item-value="codigo"
                :items="bancos_filter.filter(b => b.cuentas > 0)"
                :rules="[rules.required]"
                validate-on-blur
                autofocus
                outlined
                dense
                @change="get_cuentas(); movimiento.cuenta = null; movimiento.cbu = null"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" class="py-0">
              <v-autocomplete
                v-model="movimiento.cuenta"
                label="Cuenta"
                item-text="numero"
                item-value="codigo"
                :items="cuentas"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                @change="get_cbu()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" class="py-0">
              <v-text-field
                v-model="movimiento.cbu"
                label="CBU"
                outlined
                readonly
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-card outlined>
                <v-card-title class="body-1 py-2">
                  Movimientos
                </v-card-title>
                <v-card-text class="py-3">
                  <v-row>
                    <v-col cols="12" sm="4" class="py-0">
                      <v-autocomplete
                        v-model="movimiento.tipo"
                        label="Tipo"
                        item-text="nombre"
                        item-value="codigo"
                        :items="tipos_movimiento.filter(tm => tm.inhabilitado != 1)"
                        :rules="[rules.required]"
                        validate-on-blur
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4" class="py-0">
                      <v-text-field
                        v-model="movimiento.num_comp"
                        label="Comprobante"
                        :rules="[rules.required]"
                        validate-on-blur
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" class="py-0">
                      <FechaPicker
                        v-model="movimiento.fecha"
                        label="Fecha"
                      />
                    </v-col>
                    <v-col cols="6" sm="3" class="py-0">
                      <v-text-field
                        v-model="movimiento.importe"
                        label="Importe"
                        type="number"
                        prefix="$"
                        :readonly="movimiento.imputaciones > 0"
                        :filled="movimiento.imputaciones > 0"
                        :rules="[rules.required]"
                        validate-on-blur
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="3" class="py-0">
                      <v-text-field
                        v-model.trim="movimiento.gestor_codigo"
                        label="Cliente"
                        ref="gestorCod"
                        type="number"
                        :rules="p_movimiento.origen == 1 ? [rules.required] : []"
                        validate-on-blur
                        outlined
                        dense
                        @blur="buscar_gestor()"
                      >
                        <template v-slot:append-outer>
                          <v-icon
                            class="mt-1"
                            color="info"
                            title="Buscar gestor"
                            small
                            @click="dialog_gestor = true"
                          >
                            fas fa-search
                          </v-icon>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="11" sm="6" class="py-0">
                      <v-text-field
                        v-model.trim="movimiento.gestor_nombre"
                        class=""
                        outlined
                        readonly
                        filled
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-row class="d-flex justify-end py-3 px-3">
          <v-btn
            v-if="p_movimiento.tipo == 3"
            color="info"
            class="mt-2 mt-sm-0"
            :disabled="load || load_data"
            @click="get_cheque(false)"
          >
            <v-icon left small>fas fa-money-check</v-icon>
            Cheque
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="mt-2 mt-sm-0"
            :disabled="load || load_data"
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <BtnConfirmar
            clase="ml-2 mt-2 mt-sm-0"
            :disabled="load_data"
            :loading="load"
            @action="guardar()"
          />
        </v-row>
      </v-card-actions>
    </v-card>
    <!-- COMPONENTES -->
    <ModalGestor
      v-model="dialog_gestor"
      @seleccion="seleccionar_gestor"
    />
    <Cheque
      v-model="dialog_cheque"
      :p_nuevo="nuevo_cheque"
      :p_id="cheque_id"
    />
  </v-dialog>
</template>

<script>
// Modal para editar movimientos bancarios. Emite la accion @editar al padre cuando se modifica
import { mapState } from 'vuex'
import { get_nombre_obj_arr } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar'
import FechaPicker from '../util/FechaPicker' 
import ModalGestor from './ModalGestor'
import Cheque from './Cheque'

export default {
  data () {
    return {
      load: false,
      load_data: false,
      dialog_gestor: false,
      dialog_cheque: false,
      nuevo_cheque: false,
      cheque_id: null,
      cuentas: [],
      bancos_filter: [],
      movimiento: {},
      rules: {
        required: value => !!value || 'Campo requerido',
      }
    }
  },
  props: {
    value: Boolean,
    p_movimiento: {}
  },
  computed: {
    ...mapState('bancos', ['bancos', 'tipos_movimiento']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    FechaPicker,
    ModalGestor,
    Cheque
  },
  watch: {
    async dialog (val) {
      if (val) {
        this.load_data = true
        await this.$store.dispatch('bancos/get_tipos_movimiento')
        await this.$store.dispatch('bancos/get_bancos_habilitados', this.p_movimiento.empresa_codigo)
        this.bancos_filter = await JSON.parse(JSON.stringify(this.bancos))
        this.movimiento = await JSON.parse(JSON.stringify(this.p_movimiento))
        this.load_data = false
        await this.get_cuentas()
      } else {
        this.$refs.form.resetValidation()
      }
    }
  },
  methods: {
    async get_cheque (nuevo) {
      this.$store.state.loading = true
      await this.$store.dispatch('movBancarios/get_cheque', this.p_movimiento.num_mov)
        .then((res) => {
          this.cheque_id = res.data
          this.nuevo_cheque = nuevo
          this.dialog_cheque = true
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async guardar () {
      if (this.$refs.form.validate()) {
        if (!this.movimiento.fecha) {
          this.$store.dispatch('show_snackbar', {
            text: 'Debe ingresar una fecha',
            color: 'warning'
          })
          return
        }
        if (JSON.stringify(this.movimiento) != JSON.stringify(this.p_movimiento)) {
          this.load = true
          await this.$store.dispatch('movBancarios/modificar', {
            num_mov: this.movimiento.num_mov,
            cuenta: this.movimiento.cuenta,
            tipo: this.movimiento.tipo,
            comprobante: this.movimiento.num_comp,
            fecha: this.movimiento.fecha,
            importe: this.movimiento.importe,
            gestor: this.movimiento.gestor_codigo
          })
            .then(async (res) => {
              this.dialog = false
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success'
              })
              this.movimiento.banco_nombre = get_nombre_obj_arr(this.bancos, 'codigo', this.movimiento.banco_codigo)
              this.movimiento.num_cuenta = get_nombre_obj_arr(this.cuentas, 'codigo', this.movimiento.cuenta, 'numero')
              this.movimiento.tipo_nombre = get_nombre_obj_arr(this.tipos_movimiento, 'codigo', this.movimiento.tipo)
              // si modifico el importe hace el saldo igual al importe
              if (JSON.stringify(this.movimiento.importe) != JSON.stringify(this.p_movimiento.importe)) {
                this.movimiento.saldo = JSON.parse(JSON.stringify(this.movimiento.importe))
              }
              this.$emit('editar', this.movimiento)

              // si cambio a cheque abro el modal
              if (this.p_movimiento.tipo != 3 && this.movimiento.tipo == 3) {
                await this.get_cheque(true)
              }

            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.load = false
        } else {
          this.dialog = false
          this.$store.dispatch('show_snackbar', {
            text: 'No se realizaron cambios en el movimiento Nº ' + this.movimiento.num_mov,
            color: 'warning'
          })
        }
      }
    },
    async buscar_gestor () {
      const codigo = this.movimiento.gestor_codigo
      if (codigo) {
        this.$store.state.loading = true
        await this.$store.dispatch('movBancarios/get_gestores', { codigo: codigo })
          .then((res) => {
            switch (res.size) {
              case 0:
                this.movimiento.gestor_codigo = null
                this.movimiento.gestor_nombre = ''
                this.$store.dispatch('show_snackbar', {
                  text: 'No se encontró ningún cliente con código ' + codigo,
                  color: 'error'
                })
                this.$refs.gestorCod.focus()
                break
              case 1:
                this.movimiento.gestor_nombre = res.data[0].nombre
                break
              default:
                this.movimiento.gestor_codigo = null
                this.movimiento.gestor_nombre = ''
                this.$store.dispatch('show_snackbar', {
                  text: 'No se encontraron múltiples coincidencias para el cliente con código ' + codigo,
                  color: 'error'
                })
                this.$refs.gestorCod.focus()
                break
            }
          })
          .catch(error => {
            this.movimiento.gestor_codigo = null
            this.movimiento.gestor_nombre = ''
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
            this.$refs.gestorCod.focus()
          })
        this.$store.state.loading = false
      }
    },
    async get_cuentas () {
      this.cuentas = []
      const banco = this.movimiento.banco_codigo
      if (banco) {
        this.$store.state.loading = true
        await this.$store.dispatch('bancos/get_cuentas', {
          inhabilitada: 0,
          empresa: this.p_movimiento.empresa_codigo,
          banco: banco
        })
          .then((res) => {
            this.cuentas = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },
    get_cbu () {
      const cuenta = this.movimiento.cuenta
      if (cuenta) {
        this.movimiento.cbu = this.cuentas.find(c => c.codigo == cuenta).cbu
      }
    },
    seleccionar_gestor (gestor) {
      this.movimiento.gestor_codigo = gestor.codigo
      this.movimiento.gestor_nombre = gestor.nombre
    }
  }
}
</script>