<template>
  <v-row class="d-flex justify-center mt-0 mx-md-2 mx-n1 mb-0">
    <v-col cols="12" class="d-flex justify-end align-center pt-0 px-1">
      <div
        v-text="nuevo ? `Nuevo control ${control.tipo ? tipos_control_stk.find(i => i.codigo == control.tipo).nombre.toLowerCase() : '' }` : `Control: ${control_copy.descripcion}`"
        style="font-size: 20px"
      ></div>
      <v-spacer></v-spacer>
      <v-expand-x-transition>
        <v-switch
          v-if="!nuevo && existe && control_copy.inhabilitado != 1"
          v-model="editar"
          label="Editar"
          class="d-flex align-center py-0 my-0"
          hide-details
          dense
        ></v-switch>
      </v-expand-x-transition>
    </v-col>
    <v-card>
      <v-col cols="12">
        <v-form ref="form">
          <v-row class="pt-4">
            <v-col cols="12" sm="6" md="3" class="py-0">
              <v-autocomplete
                v-model="control.tipo"
                label="Tipo"
                item-text="nombre"
                item-value="codigo"
                :items="tipos_control_stk"
                :rules="editar ? [rules.required] : []"
                :autofocus="editar"
                :readonly="!nuevo"
                :filled="!nuevo"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="py-0">
              <v-autocomplete
                v-model="control.empresa"
                label="Empresa"
                item-text="nombre_corto"
                item-value="id"
                :items="empresas"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
                @change="get_bodegas(); get_vendedores(); control.depositos = []; control.vendedor = null"
              ></v-autocomplete>
            </v-col>
            <v-col
              v-if="eventual" 
              cols="6" sm="3"
              class="py-0"
            >
              <FechaPicker
                v-model="control.fecha_desde"
                label="Fecha desde"
                :disabled="!editar"
                :fecha_minima="moment(new Date()).format('DD/MM/YYYY')"
              />
            </v-col>
            <v-col
              v-if="eventual"
              cols="6" sm="3"
              class="py-0"
            >
              <FechaPicker
                v-model="control.fecha_hasta"
                label="Fecha hasta"
                :disabled="!editar"
                :fecha_minima="moment(new Date()).format('DD/MM/YYYY')"
              />
            </v-col>
            <v-col cols="6" sm="3" class="py-0">
              <HoraPicker
                v-model="control.hora_inicio"
                label="Hora inicio"
                :disabled="!editar"
              />
            </v-col>
            <v-col cols="6" sm="3" class="py-0">
              <HoraPicker
                v-model="control.hora_fin"
                label="Hora fin"
                :disabled="!editar"
              />
            </v-col>
            <v-col
              v-if="!nuevo"
              cols="6" sm="4" md="3"
              class="py-0"
            >
              <v-switch
                v-model="control.inhabilitado"
                label="Inhabilitado"
                class="my-0"
                :readonly="!editar"
                :true-value="1"
                :false-value="null"
                dense
              ></v-switch>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-textarea
                v-model="control.descripcion"
                label="Descripción"
                rows="3"
                counter="140"
                :rules="editar ? [rules.required, rules.max] : []"
                :readonly="!editar"
                :filled="!editar"
                auto-grow
                outlined
                dense
              ></v-textarea>
            </v-col>
            <v-col cols="12" class="pt-2 pt-sm-0">
              <v-card outlined>
                <v-card-title class="body-1 pt-2">
                  Aplica para
                </v-card-title>
                <v-card-text class="py-0 mt-2 mb-sm-2 mb-4">
                  <v-row class="d-flex justify-center">
                    <v-col cols="12" :sm="eventual ? 3 : 5" class="py-0">
                      <v-autocomplete
                        v-model="control.sucursales"
                        label="Sucursales"
                        item-text="nombre"
                        item-value="id"
                        :items="sucursales"
                        :clearable="editar"
                        :readonly="!editar"
                        :filled="!editar"
                        deletable-chips
                        small-chips
                        multiple
                        outlined
                        dense
                        @change="get_bodegas(); get_vendedores(); control.depositos = []; control.vendedor = null"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" :sm="eventual ? 5 : 7" class="py-0">
                      <v-autocomplete
                        v-model="control.depositos"
                        label="Bodegas"
                        item-text="nombre"
                        item-value="codigo"
                        :items="bodegas_filtradas"
                        :clearable="editar"
                        :readonly="!editar"
                        :filled="!editar"
                        deletable-chips
                        small-chips
                        multiple
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      v-if="!eventual"
                      cols="12"
                      md="10"
                      class="pt-0"
                    >
                      <div class="body-1">
                        Días de la semana
                      </div>
                      <div class="d-flex justify-center">
                        <DiasSemanaPicker
                          v-model="control.dias"
                          :nombre_corto="$vuetify.breakpoint.smAndDown ? true : false"
                          :disabled="!editar"
                        />
                      </div>
                    </v-col>
                    <v-col
                      v-if="!eventual"
                      cols="4"
                      md="2"
                      class="d-flex align-end pb-0"
                    >
                      <v-text-field
                        v-model="control.cantidad"
                        label="Duración"
                        type="number"
                        :suffix="control.cantidad == 1 ? 'día' : 'días'"
                        :rules="editar ? [rules.required] : []"
                        :readonly="!editar"
                        :filled="!editar"
                        validate-on-blur
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="eventual" 
                      cols="12" sm="4"
                      class="py-0"
                    >
                      <v-autocomplete
                        v-model="control.vendedor"
                        label="Responsable"
                        item-text="nombre"
                        item-value="codigo"
                        :items="vendedores"
                        :clearable="!editar  || (control.sucursales.length == 1)"
                        :readonly="!editar || !(control.sucursales.length == 1)"
                        :filled="!editar || !(control.sucursales.length == 1)"
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" class="pt-2 pt-sm-0">
              <v-card outlined>
                <v-card-title class="body-1 pt-2">
                  Controlar el stock de
                </v-card-title>
                <SelectControlIntegral
                  :disabled="!editar"
                  @result="integral"
                />
                <v-card-text class="py-0 mt-2 mb-sm-2 mb-4">
                  <v-row>
                    <v-col cols="12" sm="6" class="py-0">
                      <v-autocomplete
                        v-model="control.rubros"
                        label="Rubros"
                        item-text="nombre"
                        item-value="codigo"
                        :items="rubros"
                        :clearable="editar"
                        :readonly="!editar"
                        :filled="!editar"
                        deletable-chips
                        small-chips
                        multiple
                        outlined
                        dense
                        @change="get_categorias(); control.categorias = []"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" class="py-0">
                      <v-autocomplete
                        v-model="control.categorias"
                        label="Categorias"
                        item-text="nombre"
                        item-value="codigo"
                        :items="categorias_filtradas"
                        :clearable="editar"
                        :readonly="!editar"
                        :filled="!editar"
                        deletable-chips
                        small-chips
                        multiple
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-4">
                      <ArticulosActivos
                        v-model="control.articulos"
                        :disabled="!editar"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-card>
    <v-row class="d-flex justify-end pr-3 my-3">
      <v-btn
        class="ml-3 mt-2"
        :color="editar ? 'error' : 'secondary'"
        @click="cerrar()"
      >
        {{ editar ? 'Cancelar' : 'Volver' }}
      </v-btn>
      <BtnConfirmar
        v-if="editar"
        clase="ml-3 mt-2"
        @action="guardar()"
      />
    </v-row>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { order_list_by, valdiar_rango_fecha } from '../../util/utils'
import moment from 'moment'
import ArticulosActivos from '../../components/util/ArticulosActivos'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import FechaPicker from '../../components/util/FechaPicker'
import HoraPicker from '../../components/util/HoraPicker'
import DiasSemanaPicker from '../../components/util/DiasSemanaPicker'
import SelectControlIntegral from '../../components/stock/SelectControlIntegral'

export default {
  data () {
    return {
      moment: moment,
      id: this.$route.params.id,
      nuevo: false,
      editar: false,
      existe: false,
      eventual: false,
      vendedores: [],
      bodegas_filtradas: [],
      categorias_filtradas: [],
      control: {
        tipo: null,
        empresa: null,
        sucursales: [],
        depositos: [],
        categorias: [],
        articulos: [],
        dias: [],
        descripcion: null,
        hora_inicio: null,
        hora_fin: null,
        fecha_desde: null,
        fecha_hasta: null,
        vendedor: null,
        cantidad: 1,
        rubros: []
      },
      rules: {
        required: value => !!value || 'Campo requerido',
        max: value => (value ? value.length : 0) <= 140 || 'Máximo 140 caracteres'
      },
      // variables de respaldo
      control_copy: {}
    }
  },
  async created () {
    await this.get_datos()
    if (this.id) {
      // obtiene los valores
      await this.get_control()
    } else {
      this.nuevo = true
      this.editar = true
    }
  },
  computed: {
    ...mapState(['empresas', 'sucursales']),
    ...mapState('rubros', ['rubros']),
    ...mapState('categorias', ['categorias']),
    ...mapState('controlStock', ['tipos_control_stk']),
    ...mapState('bodegas', ['bodegas_user'])
  },
  components: {
    ArticulosActivos,
    BtnConfirmar,
    FechaPicker,
    HoraPicker,
    DiasSemanaPicker,
    SelectControlIntegral
  },
  watch: {
    'control.tipo' (val) {
      if (val == 1) {
        this.eventual = true
      } else {
        this.eventual = false
      }
      if (this.nuevo) {
        this.control = {
          tipo: val,
          empresa: this.control.empresa,
          sucursales: [],
          depositos: [],
          categorias: [],
          articulos: [],
          dias: [],
          descripcion: this.control.descripcion,
          hora_inicio: null,
          hora_fin: null,
          fecha_desde: null,
          fecha_hasta: null,
          vendedor: null,
          cantidad: 1,
          rubros: []
        }
        this.vendedores = []
        this.bodegas_filtradas = []
        this.categorias_filtradas = []
        this.$refs.form.resetValidation()
      }
    },
    'control.dias' (val) {
      if (this.editar) {
        this.control.cantidad = 1
      }
    },
    async editar (val) {
      if (val) {
        this.$refs.form.resetValidation()
        await this.get_vendedores()
      } else {
        // vuelve a dejar los datos del control como estaban cuando hizo la consulta
        this.control = await JSON.parse(JSON.stringify(this.control_copy))
        this.get_categorias()
        this.get_bodegas()
      }
    }
  },
  methods: {
    integral (res) {
      this.control.rubros = res.rubros
      this.get_categorias()
      this.control.categorias = res.categorias
    },
    async guardar () {
      if (this.$refs.form.validate()) {

        ///  VALIDACIONES  ///

        if (this.eventual && !(this.control.fecha_desde && this.control.fecha_hasta)) {
          this.$store.dispatch('show_snackbar', {
            text: 'Debe seleccionar una fecha',
            color: 'warning'
          })
          return
        }
        if (this.eventual && !valdiar_rango_fecha(this.control.fecha_hasta, this.control.fecha_desde)) {
          this.$store.dispatch('show_snackbar', {
            text: 'La fecha desde no puede ser mayor que la fecha hasta',
            color: 'warning'
          })
          return
        }
        if (!this.control.hora_inicio || !this.control.hora_fin) {
          this.$store.dispatch('show_snackbar', {
            text: 'Debe seleccionar una hora',
            color: 'warning'
          })
          return
        }
        if (this.control.hora_inicio >= this.control.hora_fin) {
          this.$store.dispatch('show_snackbar', {
            text: 'La hora de inicio no puede ser mayor o igual que la hora de finalizacion',
            color: 'warning'
          })
          return
        }
        if (!this.eventual && this.control.dias.length == 0) {
          this.$store.dispatch('show_snackbar', {
            text: 'Debe seleccionar al menos un día de la semana',
            color: 'warning'
          })
          return
        }
        if (!this.eventual && (this.control.cantidad < 1 || this.control.cantidad > 6)) {
          this.$store.dispatch('show_snackbar', {
            text: 'La cantidad de días debe ser un numero entre 1 y 6',
            color: 'warning'
          })
          return
        }
        if (this.control.articulos.length == 0 && this.control.categorias.length == 0)  {
          this.$store.dispatch('show_snackbar', {
            text: 'Debe seleccionar al menos una categoria o un articulo',
            color: 'warning'
          })
          return
        }

        ///  INSERCION / EDICION  ///

        this.$store.state.loading = true
        if (this.nuevo) {

          // crea la data para enviar
          let data = await this.formatear_datos()

          await this.$store.dispatch('controlStock/nueva_configuracion', data)
            .then((res) => {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success'
              })
              this.refrescar_datos()
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })

        } else {
          // solo si cambio algun valor actualiza
          if (JSON.stringify(this.control) != JSON.stringify(this.control_copy)) {

            // variables para determinar si modifico o no un array
            let suc = 0
            let dep = 0
            let cat = 0
            let art = 0
            let dia = 0

            // verifica si modifico alguno de los array
            if (this.modifico_array('sucursales')) suc = 1
            if (this.modifico_array('depositos')) dep = 1
            if (this.modifico_array('categorias')) cat = 1
            if (this.modifico_array('articulos')) art = 1
            if (this.modifico_array('dias')) dia = 1

            // crea la data para enviar
            let data = await this.formatear_datos()

            data.suc = suc
            data.dep = dep
            data.cat = cat
            data.art = art
            data.dia = dia

            await this.$store.dispatch('controlStock/editar_configuracion', data)
              .then((res) => {
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success'
                })
                this.refrescar_datos()
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })

          } else {
            this.$store.dispatch('show_snackbar', {
              text: 'No se realizaron cambios en el control de stock: los campos nuevos son iguales a los anteriores',
              color: 'warning',
              timeout: 4000
            })
          }

        }
        this.$store.state.loading = false

      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Complete los campos requeridos',
          color: 'warning'
        })
      }
    },
    async get_control () {
      this.$store.state.loading = true
      await this.$store.dispatch('controlStock/get_configuracion', this.id)
        .then(async (res) => {
          this.control = await res.data
          this.get_categorias()
          this.get_bodegas()
          // hace una copia del control
          this.control_copy = JSON.parse(JSON.stringify(this.control))
          this.existe = true
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async get_vendedores () {
      this.vendedores = []
      const empresa = this.control.empresa
      const sucursales = this.control.sucursales
      if (this.eventual && empresa && sucursales.length == 1) {
        this.$store.state.loading = true
        await this.$store.dispatch('controlStock/get_responsables', sucursales[0])
          .then((res) => {
            this.vendedores = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        order_list_by(this.vendedores, 'nombre')
        this.$store.state.loading = false
      }
    },
    async get_datos () {
      this.$store.state.loading = true
      await this.$store.dispatch('controlStock/get_tipos')
      await this.$store.dispatch('rubros/get_rubros_habilitados')
      await this.$store.dispatch('categorias/get_categorias_habilitadas')
      await this.$store.dispatch('bodegas/get_bodegas_user')
      this.$store.state.loading = false
    },
    get_bodegas () {
      this.bodegas_filtradas = []
      const empresa = this.control.empresa
      const sucursales = this.control.sucursales
      if (empresa && sucursales.length > 0) {
        sucursales.forEach(sucursal => {
          let bodegas = this.bodegas_user.filter(b => b.empresa_codigo == empresa && b.sucursal_codigo == sucursal)
          bodegas.forEach(bodega => {
            this.bodegas_filtradas.push(bodega)
          })
        })
        order_list_by(this.bodegas_filtradas, 'nombre')
      }
    },
    get_categorias () {
      this.categorias_filtradas = []
      this.control.rubros.forEach(rubro => {
        let categorias = this.categorias.filter(cat => cat.rubro_codigo == rubro)
        categorias.forEach(categoria => {
          this.categorias_filtradas.push(categoria)
        })
      })
      order_list_by(this.categorias_filtradas, 'nombre')
    },
    get_array_articulos () {
      let articulos = []
      this.control.articulos.forEach(articulo => {
        articulos.push(articulo.codigo)
      })
      return articulos
    },
    get_array_dias () {
      let dias = []
      this.control.dias.forEach(dia => {
        dias.push(dia + 1)
      })
      return dias
    },
    async formatear_datos () {
      let data = await JSON.parse(JSON.stringify(this.control))
      // formatea el array de articulos para dejar solo los codigos
      data.articulos = this.get_array_articulos()
      // incrementa en 1 el id de los dias pq en la base el domingo empieza en 1 y aca lo toma como 0
      data.dias = this.get_array_dias()
      return data
    },
    refrescar_datos () {
      // actualiza los datos del control en la vista y bloquea la edicion
      this.control_copy = JSON.parse(JSON.stringify(this.control))
      this.editar = false
    },
    modifico_array (valor) {
      return JSON.stringify(this.control[valor].sort()) != JSON.stringify(this.control_copy[valor].sort())
    },
    cerrar () {
      window.close()
    }
  }
}
</script>