<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <!-- Filtros -->
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <!-- Titulo Filtros -->
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar(1)">
                <!-- Empresa, Banco y Cuentas -->
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="2" md="2" class="py-1">
                    Empresa
                    <v-autocomplete
                      v-model="selectedEmpresa"
                      item-text="nombre_corto"
                      :items="empresas"
                      hide-details
                      outlined
                      dense
                      return-object
                      clearable
                      @change="getBancos(1)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="5" md="5" class="py-1">
                    Banco
                    <v-autocomplete
                      v-model="selectedBanco"
                      item-text="banco_nombre"
                      :items="bancos"
                      hide-details
                      outlined
                      dense
                      return-object
                      clearable
                      @change="getCuentas(1)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="5" md="5" class="py-1">
                    Cuenta
                    <v-autocomplete
                      v-model="selectedCuenta"
                      item-text="nro_cuenta"
                      :items="cuentas"
                      hide-details
                      outlined
                      dense
                      return-object
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <!-- Tipos Extractos, Tipos Movimientos y Fecha -->
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="6" md="6" class="py-1">
                    Tipo Extracto
                    <v-autocomplete
                      v-model="selectedTipoEx"
                      item-text="tipo_extracto_nom"
                      :items="tiposExtractos"
                      hide-details
                      outlined
                      dense
                      return-object
                      clearable
                      multiple
                      chips
                      deletable-chips
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Tipo Movimiento
                    <v-autocomplete
                      v-model="selectedTipoMov"
                      item-text="tipo_mov_nom"
                      :items="tiposMovimientos"
                      hide-details
                      outlined
                      dense
                      return-object
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Estado Conciliación
                    <v-autocomplete
                      v-model="selectedEstado"
                      item-text="estado_nombre"
                      :items="estadosConciliacion"
                      hide-details
                      outlined
                      dense
                      return-object
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <!-- Archivo Id, Fechas y Botones -->
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="2" md="2" class="py-1">
                    Archivo Id
                    <v-text-field
                      v-model="archivoId"
                      outlined
                      dense
                      type="number"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" class="py-1">
                    Extracto ID
                    <v-text-field
                      v-model="extractoId"
                      outlined
                      dense
                      type="number"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" :sm="big ? 6 : 3" class="py-1">
                    Fecha
                    <FechaPickerRango
                      v-model="fecha"
                      :limpiar.sync="limpiar_fecha"
                      @changeSize="changeSize"
                    />
                  </v-col>
                  <v-col cols="12" :sm="big ? 2 : 5" class="py-0">
                    <BtnFiltro
                      :loading="load"
                      @clear="limpiar()"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Extractos Bancarios -->
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-2">
          <v-col cols="12">
            <v-card
              elevation="2"
              outlined
              id="SeccionExtractos"
            >
              <v-card-title class="pb-0">
                Extractos Bancarios
              </v-card-title>
              <v-card-text>
                <v-data-table
                  class="cebra elevation-2 mt-2"
                  :headers="headersEx"
                  :items="extractos"
                  :loading="load"
                  item-key="id"
                  dense
                  :search="searchEx"
                  single-expand
                  single-select
                  :expanded.sync="expanded"
                  @click:row="rowClick"
                  @contextmenu:row="clickDerecho"
                  :item-class="miBand ? ponerSinColor : ponerColor"
                >
                  <!-- Buscador en el datatable -->
                  <template v-slot:top>
                    <v-row class="d-flex justify-end pb-2" no-gutters>
                      <v-col cols="6" sm="3">
                        <SearchDataTable
                          v-model="searchEx"
                        />
                      </v-col>
                    </v-row>
                  </template>
                  <!-- Msj que se mostrara si no existen resultados -->
                  <template v-slot:no-data>
                    <v-alert
                      class="mx-auto mt-4"
                      max-width="400"
                      type="warning"
                      border="left"
                      dense
                      text
                    >
                      No hay datos para los filtros seleccionados
                    </v-alert>
                  </template>
                  <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
                  <template
                    v-for="header in headersEx.filter((header) => header.hasOwnProperty('formatter'))"
                    v-slot:[`item.${header.value}`]="{ value }"
                  >
                    {{ header.formatter(value) }}
                  </template>
                  <!-- Estado Conciliacion -->
                  <template v-slot:[`item.estado_nombre`]="{ item }">
                    <v-chip :color="item.color" small dark style="font-size: 12px;">{{ item.estado_nombre }}</v-chip>
                  </template>
                  <!-- Conciliado Manual -->
                  <template v-slot:[`item.conciliacion_manual`]="{ item }">
                    {{ item.conciliacion_manual == 0 ? 'NO' : 'SI' }}
                  </template>
                  <!-- Tipo Extracto -->
                  <template v-slot:[`item.tipo_extracto_nom`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          color="info"
                          class="mr-2"
                          small
                        >
                          fas fa-info-circle
                        </v-icon>
                      </template>
                      <span>
                        {{ item.descripcion }}
                      </span>
                    </v-tooltip>
                    {{ item.tipo_extracto_nom }}
                  </template>
                  <!-- Acciones -->
                  <template v-slot:[`item.acciones`]="{ item }">
                    <v-tooltip bottom v-if="item.estado_id != 2">
                      <template v-slot:activator="{ on }">
                        <v-btn small icon color="success" @click.stop="confConciliarOK(item)" v-on="on">
                          <v-icon small>far fa-check-circle</v-icon>
                        </v-btn>
                      </template>
                      <span>Conciliar OK</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.estado_id == 1">
                      <template v-slot:activator="{ on }">
                        <v-btn small icon color="warning" @click.stop="confConciliarObs(item)" v-on="on">
                          <v-icon small>far fa-check-circle</v-icon>
                        </v-btn>
                      </template>
                      <span>Conciliar con Observaciones</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.estado_id != 1">
                      <template v-slot:activator="{ on }">
                        <v-btn small icon color="error" @click.stop="controlaModal(item,null, 1)" v-on="on">
                          <v-icon small>far fa-times-circle</v-icon>
                        </v-btn>
                      </template>
                      <span>Desconciliar</span>
                    </v-tooltip>
                  </template>
                  <!-- Contenido de la Fila expandida -->
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <v-simple-table
                        class="ma-2"
                        dense
                      >
                        <thead>
                          <tr>
                            <th class="text-center">Cod.</th>
                            <th class="text-center" v-if="!conciliadoConFactura">Nro. Cta.</th>
                            <th class="text-center" v-if="item.extracto_id != 0 && !conciliadoConFactura">Banco</th>
                            <th class="text-center">Fecha</th>
                            <th class="text-center">Nro. Comp.</th>
                            <th class="text-center" v-if="!conciliadoConFactura">Tipo Mov.</th>
                            <!-- <th class="text-center">Pto. Vta.</th> -->
                            <th class="text-center">Estado</th>
                            <th class="text-center" v-if="item.extracto_id == 0">Sucursal</th>
                            <th class="text-center" v-if="item.extracto_id == 0 && !conciliadoConFactura">Origen</th>
                            <th class="text-center" v-if="!conciliadoConFactura">Pedido</th>
                            <th class="text-center">Importe</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(mov, index) in item.mov_conciliado"
                            :key="index"
                          >
                            <td class="text-center">{{ mov.movimiento_codigo }}</td>
                            <td class="text-center" v-if="mov.tipo != 5">
                              <v-tooltip bottom v-if="mov.tipo != 3">
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                    v-on="on"
                                    color="info"
                                    class="ma-1"
                                    small
                                  >
                                    fas fa-university
                                  </v-icon>
                                </template>
                                <div>
                                  Banco: <strong class="ml-1"><span>{{mov.banco_nombre}}</span></strong>
                                </div>
                              </v-tooltip>
                              {{ mov.numero_cuenta }}
                            </td>
                            <td class="text-center" v-if="item.extracto_id != 0 && mov.tipo != 5">{{ mov.banco_nombre }}</td>
                            <td class="text-center">{{ mov.fecha }}</td>
                            <td class="text-center">{{ mov.numero_comprobante }}</td>
                            <td class="text-center" v-if="mov.tipo != 5">{{ mov.tipo_mov_nombre }}</td>
                            <!-- <td class="text-center">{{ mov.pto_vta_nombre }}</td> -->
                            <td class="text-center">{{ mov.mov_estado_nombre }}</td>
                            <td class="text-center" v-if="item.extracto_id == 0">{{ mov.sucursal_nombre }}</td>
                            <td class="text-center" v-if="item.extracto_id == 0 && mov.tipo != 5">
                              <v-tooltip bottom v-if="mov.origen_codigo == 1">
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                    v-on="on"
                                    color="info"
                                    class="ma-1"
                                    small
                                  >
                                    fas fa-info-circle
                                  </v-icon>
                                </template>
                                <div>
                                  Cliente Cód.: <strong class="ml-1"><span>{{mov.cliente_codigo}}</span></strong>
                                </div>
                                <div>
                                  Cliente Nom.: <strong class="ml-1"><span>{{mov.cliente_nombre}}</span></strong>
                                </div>
                              </v-tooltip>
                              {{ mov.origen_nombre }}
                            </td>
                            <td class="text-center" v-if="mov.tipo != 5">{{ mov.nro_pedido }}</td>
                            <td class="text-center">{{ format_money(mov.importe) }}</td>
                            <td class="text-center">
                              <v-tooltip bottom v-if="mov.tipo != 3 && mov.origen_codigo != 4">
                                <template v-slot:activator="{ on }">
                                  <v-btn small icon color="success" @click="verPedido(mov)" v-on="on">
                                    <v-icon small>far fa-eye</v-icon>
                                  </v-btn>
                                </template>
                                <span v-if="mov.origen_codigo != 4">Ver{{ mov.tipo != 5? ' en Caja' : ''}}</span>
                                <span v-else>Ver Liquidación</span>
                              </v-tooltip>
                              <v-tooltip bottom v-if="mov.tipo != 3 && mov.tipo!=5">
                                <template v-slot:activator="{ on }">
                                  <v-btn small icon color="indigo" @click="modificarMovimiento(mov)" v-on="on">
                                    <v-icon small>fas fa-pencil-alt</v-icon>
                                  </v-btn>
                                </template>
                                <span>Modificar Movimiento Bancario</span>
                              </v-tooltip>
                              <v-tooltip bottom v-if="mov.tipo==5">
                                <template v-slot:activator="{ on }">
                                  <v-btn small icon color="error" @click.stop="controlaModal(item, mov,2)" v-on="on">
                                    <v-icon small>far fa-times-circle</v-icon>
                                  </v-btn>
                                </template>
                                <span>Desconciliar factura</span>
                              </v-tooltip>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-text-field
                        v-if="!conciliadoConFactura"
                        outlined
                        dense
                        label="Observación"
                        v-model="item.observacion"
                        hide-details
                        type="text"
                        class="ma-2"
                        :readonly="item.estado_id == 2"
                      >
                        <template v-slot:append>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn small icon color="success" @click="confGuardarObs(item)" v-on="on">
                                <v-icon small>far fa-save</v-icon>
                              </v-btn>
                            </template>
                            <span>Guardar Observación</span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </td>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- Estimación de Movimientos Bancarios -->
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-0">
          <v-col cols="12">
            <v-card
              elevation="2"
              outlined
              id="SeccionEstimaciones"
            >
              <v-card-title class="pb-0">
                <p v-if="expanded.length > 0 && expanded[0].estado_id == 1" style="word-break: normal;" class="text-center">Estimación para el Extracto {{ expanded[0].id }}, Fecha {{ expanded[0].fecha }}, Nro. de Comprobante {{ expanded[0].nro_comprobante }}, Nro. Cuenta {{ expanded[0].numero_cuenta }}, Importe {{ format_money(expanded[0].importe) }}</p>
                <p v-else style="word-break: normal;">Estimación de Extractos Bancarios - Sin Extracto seleccionado.</p>
              </v-card-title>
              <v-card-text>
                <v-form @submit.prevent="buscarEst">
                  <!-- Filtros para buscar Movimientos Bancarios, Cod. Mov. Bancario, Empresa, Banco y Cuenta -->
                  <v-row :no-gutters="$vuetify.breakpoint.xs">
                    <v-col cols="12" sm="2" md="2" class="py-1">
                      Mov. Bancario
                      <v-text-field
                        v-model="filtrosEst.movimiento_codigo"
                        outlined
                        dense
                        type="number"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="py-1">
                      Empresa
                      <v-autocomplete
                        v-model="filtrosEst.empresa_codigo"
                        item-text="nombre_corto"
                        item-value="id"
                        :items="empresas"
                        hide-details
                        outlined
                        dense
                        clearable
                        @change="getBancos(2)"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" class="py-1">
                      Banco
                      <v-autocomplete
                        v-model="filtrosEst.banco_codigo"
                        item-text="banco_nombre"
                        :items="bancosEst"
                        item-value="banco_codigo"
                        hide-details
                        outlined
                        dense
                        clearable
                        @change="getCuentas(2)"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" class="py-1">
                      Cuenta
                      <v-autocomplete
                        v-model="filtrosEst.cuenta_codigo"
                        item-text="nro_cuenta"
                        item-value="cuenta_codigo"
                        :items="cuentasEst"
                        hide-details
                        outlined
                        dense
                        clearable
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <!-- Estado, Fecha Desde/Hasta, Importe Desde/Hasta y Nro de Comprobante -->
                  <v-row :no-gutters="$vuetify.breakpoint.xs">
                    <!-- <v-col cols="12" sm="2" md="2" class="py-1">
                      Estado
                      <v-autocomplete
                        v-model="filtrosEst.estado"
                        item-text="nombre"
                        item-value="codigo"
                        :items="estados"
                        hide-details
                        outlined
                        dense
                        clearable
                      ></v-autocomplete>
                    </v-col> -->
                    <v-col cols="12" sm="2" md="2" class="py-1">
                      Fecha Desde
                      <FechaPicker
                        v-model="filtrosEst.fecha_desde"
                        :hideDetails="true"
                      />
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="py-1">
                      Fecha Hasta
                      <FechaPicker
                        v-model="filtrosEst.fecha_hasta"
                        :hideDetails="true"
                      />
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="py-1">
                      Importe Desde
                      <v-text-field
                        v-model="filtrosEst.importe_desde"
                        outlined
                        dense
                        type="number"
                        hide-details
                        prefix="$"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="py-1">
                      Importe Hasta
                      <v-text-field
                        v-model="filtrosEst.importe_hasta"
                        outlined
                        dense
                        type="number"
                        hide-details
                        prefix="$"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" class="py-1">
                      Nro. Comp.
                      <v-text-field
                        v-model="filtrosEst.nro_comprobante"
                        outlined
                        dense
                        type="text"
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- Tipo Movimiento, Origen Mov, Sucursales y Botones -->
                  <v-row :no-gutters="$vuetify.breakpoint.xs">
                    <v-col cols="12" sm="3" md="3" class="py-1">
                      Tipo Movimiento
                      <v-autocomplete
                        v-model="filtrosEst.tipo_movimiento"
                        item-text="tipo_mov_nombre"
                        item-value="tipo_mov_codigo"
                        :items="tipos_movim"
                        hide-details
                        outlined
                        dense
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" class="py-1">
                      Origen Movimiento
                      <v-autocomplete
                        v-model="filtrosEst.origen_movimiento"
                        item-text="nombre"
                        item-value="codigo"
                        :items="origenes"
                        hide-details
                        outlined
                        dense
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" class="py-1">
                      Sucursal
                      <v-autocomplete
                        v-model="filtrosEst.sucursal_codigo"
                        item-text="nombre"
                        item-value="id"
                        :items="sucursales"
                        hide-details
                        outlined
                        dense
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" class="py-0">
                      <BtnFiltro
                        :loading="loadEst"
                        @clear="limpiarEst()"
                        :disabled="expanded.length == 0"
                      />
                    </v-col>
                  </v-row>
                  <!-- Listado de Movimientos Estimados -->
                  <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-0">
                    <v-col cols="12" class="pt-0">
                      <v-data-table
                        class="cebra elevation-2 mt-2"
                        :headers="headersEst"
                        :items="estimaciones"
                        :loading="loadEst"
                        dense
                        :search="searchEst"
                      >
                        <!-- Buscador en el datatable -->
                        <template v-slot:top>
                          <v-row class="d-flex justify-end pa-2" no-gutters>
                            <v-col cols="6" sm="3">
                              <SearchDataTable
                                v-model="searchEst"
                              />
                            </v-col>
                          </v-row>
                        </template>
                        <!-- Msj que se mostrara si no existen resultados -->
                        <template v-slot:no-data>
                          <v-alert
                            class="mx-auto mt-4"
                            max-width="400"
                            type="warning"
                            border="left"
                            dense
                            text
                          >
                            No hay datos para los filtros seleccionados
                          </v-alert>
                        </template>
                        <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
                        <template
                          v-for="header in headersEst.filter((header) => header.hasOwnProperty('formatter'))"
                          v-slot:[`item.${header.value}`]="{ value }"
                        >
                          {{ header.formatter(value) }}
                        </template>
                        <!-- Acciones -->
                        <template v-slot:[`item.acciones`]="{ item }">
                          <v-tooltip bottom v-if="item.mov_estado_codigo == 1">
                            <template v-slot:activator="{ on }">
                              <v-btn small icon color="success" @click="confConciliarManual(item)" v-on="on" :disabled="expanded.length == 0">
                                <v-icon small>far fa-check-circle</v-icon>
                              </v-btn>
                            </template>
                            <span>Conciliar Manual</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- Modal Confirmar Conciliación Manual -->
    <v-dialog
      v-model="dialogConfConcManual"
      max-width="400px"
    >
      <v-card>
        <v-card-title>
          Conciliar Manual
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialogConfConcManual = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="body-1 pt-4">
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" class="py-2">
              <span v-html="msjConfirmar"></span>
            </v-col>
          </v-row>
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" class="py-2">
              <v-textarea
                v-model="selectedMovEstimado.observacion"
                outlined
                dense
                label="Observación a Guardar"
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            color="success"
            class="mr-2"
            @click="conciliarManual()"
          >
            Sí
          </v-btn>
          <v-btn
            color="error"
            @click="dialogConfConcManual = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal Confirmar Desconciliar -->
    <ModalConfirmar 
      :activoDialog="dialogDesconciliar"
      :texto="msjConfirmar"
      @action="desconciliar()"
      @setearModalConfAccion="setModalDesconciliar"
    />
    <!-- Modal Confirmar Conciliar OK -->
    <ModalConfirmar 
      :activoDialog="dialogConciliarOK"
      :texto="msjConfirmar"
      @action="conciliarOK()"
      @setearModalConfAccion="setModalConciliaOK"
    />
    <!-- Modal Confirmar Conciliar con Obs -->
    <ModalConfirmar 
      :activoDialog="dialogConciliarObs"
      :texto="msjConfirmar"
      @action="conciliarObs()"
      @setearModalConfAccion="setModalConciliaObs"
    />
    <!-- Modal Modificar Movimiento Bancario -->
    <ModificarMovBancario 
      v-model="dialogModMovBco"
      :p_movimiento="movimiento"
      @editar="modMovimiento"
    />
    <!-- Modal Confirmar Modificar Observación -->
    <ModalConfirmar 
      :activoDialog="dialogModObs"
      :texto="msjConfirmar"
      @action="guardarObs()"
      @setearModalConfAccion="setModalModObs"
    />
    <!-- Menu del Click Derecho -->
    <v-menu
      v-model="menu.activo"
      :position-x="menu.x"
      :position-y="menu.y"
      absolute
      offset-y
      v-if="actualConciliacion.estado_id == 1"
    >
      <v-list>
        <v-list-item
          v-if="actualConciliacion.estado_id != 2"
          @click="confConciliarOK(actualConciliacion)">
          <v-list-item-title>
            <v-row>
              <v-col cols="2">
                <v-icon small color="success" title="Conciliar OK" class="pe-2">far fa-check-circle</v-icon>
              </v-col>
              <v-col cols="10">
                Conciliar OK
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="confConciliarObs(actualConciliacion)" v-if="actualConciliacion.estado_id == 1">
          <v-list-item-title>
            <v-row>
              <v-col cols="2">
                <v-icon small color="warning" title="Con observación">far fa-check-circle</v-icon>
              </v-col>
              <v-col cols="10">
                Conciliar con Observación
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item>
        <!-- <v-list-item
          @click="confDesconciliar(actualConciliacion)"
          v-if="actualConciliacion.estado_id != 1"
        >
          <v-list-item-title>
            <v-row>
              <v-col cols="2" class="text-center">
                <v-icon color="error" small left>far fa-times-circle</v-icon>
              </v-col>
              <v-col cols="10">
                Desconciliar
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item> -->
        <v-list-item @click="conciliarCompraModal(actualConciliacion)" v-if="actualConciliacion.estado_id == 1">
          <v-list-item-title>
            <v-row>
              <v-col cols="2">
                <v-icon small color="accent" title="Conciliar Compra">far fa-check-circle</v-icon>
              </v-col>
              <v-col cols="10">
                Conciliar con Fact compra
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- ModalGetFactCompraVue -->
     <ModalGetFactCompraVue
      v-model="objModal.activo"
      :datos="objModal"
      @conciliar="conciliarCompra"
     />
    <!-- ModalParaSeleccion Estado de control -->
    <v-dialog
      v-model="objSelec.dialogSelecEstado"
      width="400"
      :persistent="objSelec.dialogSelecEstado"
      scrollable
    >
      <v-card>
        <v-card-title>
          <div>
            Estado de control
          </div>
          <v-spacer></v-spacer>
          <v-btn right icon @click="objSelec.dialogSelecEstado = false" x-small>
              <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-2">
          Seleccionar
          <v-autocomplete
            v-model="estado_control"
            item-text="estado_control_nombre"
            item-value="estado_control_id"
            :items="estados_control_fact"
            hide-details
            clearable
            outlined
            dense
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions class="pa-0">
          <v-row class="ma-0 d-flex justify-end">
            <v-col cols="12" md="4" class="d-flex justify-end pb-4">
              <v-btn
                class="mr-2"
                color="secondary"
                @click="objSelec.dialogSelecEstado = false"
              >Cancelar</v-btn>
              <v-btn
                color="success"
                @click="confDesconciliar()"
              >Aceptar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FechaPickerRango from '../../components/util/FechaPickerRango.vue'
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import FechaPicker from '../../components/util/FechaPicker.vue'
import ModalConfirmar from '../../components/util/ModalConfirmar.vue'
import ModificarMovBancario from '../../components/auditoria/ModificarMovBancario.vue'
import { objetoNoVacio, cadenaVacia, format_money, roundNumber } from '../../util/utils'
import ModalGetFactCompraVue from '../../components/auditoria/ModalGetFactCompra.vue'
import moment from 'moment'

export default {
  data() {
    return {
      format_money: format_money,
      panel: 0,
      selectedEmpresa: {},
      empresas: [],
      selectedBanco: {},
      bancos: [],
      bancosEst: [],
      selectedCuenta: {},
      cuentas: [],
      cuentasEst: [],
      limpiar_fecha: false,
      fecha: [ null, null ],
      big: false,
      load: false,
      headersEx: [
        { text: 'ID', align: 'center', value: 'id' },
        { text: 'Fecha Concilia.', align: 'center', value: 'fecha_concilia' },
        { text: 'Estado', align: 'left', value: 'estado_nombre' },
        { text: 'Fecha', align: 'center', value: 'fecha' },
        { text: 'Nro. Comp.', align: 'center', value: 'nro_comprobante' },
        { text: 'Tipo Extracto', align: 'left', value: 'tipo_extracto_nom' },
        { text: 'Man.', align: 'center', value: 'conciliacion_manual' },
        { text: 'Importe', align: 'right', value: 'importe', formatter: format_money },
        { text: 'Diferencia', align: 'right', value: 'diferencia', formatter: format_money },
        { text: 'Saldo', align: 'right', value: 'saldo', formatter: format_money },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      extractos: [],
      searchEx: '',
      loadEst: false,
      headersEst: [
        { text: 'Cod.', align: 'center', value: 'movimiento_codigo' },
        { text: 'Nro. Cta.', align: 'left', value: 'numero_cuenta' },
        { text: 'Fecha', align: 'center', value: 'fecha' },
        { text: 'Nro. Comp.', align: 'center', value: 'numero_comprobante' },
        { text: 'Tipo Mov.', align: 'left', value: 'tipo_mov_nombre' },
        //{ text: 'Pto. Vta.', align: 'center', value: 'pto_vta_nombre' },
        { text: 'Estado', align: 'left', value: 'mov_estado_nombre' },
        { text: 'Sucursal', align: 'left', value: 'sucursal_nombre' },
        { text: 'Origen', align: 'left', value: 'origen_nombre' },
        //{ text: 'Empresa', align: 'center', value: 'empresa_nombre' },
        { text: 'Importe', align: 'right', value: 'importe', formatter: format_money },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      estimaciones: [],
      searchEst: '',
      expanded: [],
      tipos_movim: [],
      estados: [],
      sucursales: [],
      origenes: [],
      selectedMovEstimado: {},
      selectedExtracto: {},
      filtrosEst: {
        movimiento_codigo: null,
        empresa_codigo: null,
        banco_codigo: null,
        cuenta_codigo: null,
        tipo_movimiento: null,
        estado: null,
        fecha_desde: null,
        fecha_hasta: null,
        importe_desde: null,
        importe_hasta: null,
        nro_comprobante: null,
        sucursal_codigo: null,
        origen_movimiento: null
      },
      dialogConfConcManual: false,
      msjConfirmar: '',
      dialogDesconciliar: false,
      dialogConciliarOK: false,
      dialogConciliarObs: false,
      dialogModObs: false,
      selectedTipoEx: [],
      tiposExtractos: [],
      selectedTipoMov: {},
      tiposMovimientos: [],
      archivoId: '',
      selectedEstado: {},
      estadosConciliacion: [],
      extractoId: '',
      dialogModMovBco: false,
      movimiento: {},
      filtro: {
        empresa: null,
        sucursal: null,
        ptovta: null,
        banco: null,
        num_mov: null,
        num_comp: null,
        tipo: null,
        origen: null,
        cuenta: null,
        gestor: null,
        forma_cobro: null,
        tarjeta: null,
        auditado: null,
        estado: 1,
        fecha_desde: null,
        fecha_hasta: null
      },
      miBand: false,
      actualConciliacion: {},
      menu: {
        activo: false,
        x: 0,
        y: 0
      },
      objModal:{
        activo: false,
        infoExtracto:{
          id_conciliacion: 0,
        }
      },
      conciliadoConFactura: false,
      tabla_relacion_id: 0,
      relacion_id: 0,
      estado_control: 0,
      objSelec:{
        dialogSelecEstado: false,
        individual: false,
        mov: [],
      },
      estados_control_fact: [
        { estado_control_id: 1, estado_control_nombre: "PENDIENTE" },
        { estado_control_id: 2, estado_control_nombre: "OK - PAGAR" },
        { estado_control_id: 3, estado_control_nombre: "OBSERVADO" },
        { estado_control_id: 4, estado_control_nombre: "PAGADO" },
        { estado_control_id: 5, estado_control_nombre: "CHEQUE EMITIDO" },
        { estado_control_id: 6, estado_control_nombre: "NO CORRESPONDE" },
      ],
    }
  },
  created () {
    this.initForm()
  },
  methods: {
    async initForm(){
      this.empresas = JSON.parse(localStorage.getItem('empresas'))
      this.sucursales = JSON.parse(localStorage.getItem('sucursales'))
      this.$store.state.loading = true
      let initPeticion = await this.$store.dispatch('conciliacionBancaria/initConciliacionBanco')
      this.$store.state.loading = false
      if (initPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error',
        })
        return
      }
      // paso a asignar los datos
      this.tipos_movim = initPeticion.tipos_movimientos
      this.estados = initPeticion.estados
      this.origenes = initPeticion.origenes
      this.tiposExtractos = initPeticion.tipos_extractos
      this.tiposMovimientos = initPeticion.tipos_mov_extractos
      this.estadosConciliacion = initPeticion.estados_conciliacion
      // si la url vino con filtros, filtra los datos
      const req = this.$route.params.filtro
      if (req){
        // paso a llenar los filtros
        this.$store.state.loading = true
        let datosPeticion = await this.$store.dispatch('conciliacionBancaria/getDatosExtracto', {
          extracto_id: req
        })
        if (datosPeticion.resultado == 0){
          this.$store.state.loading = false
          this.$store.dispatch('show_snackbar', {
            text: datosPeticion.msj,
            color: 'error',
          })
          return
        }
        if (datosPeticion.extracto.length == 0){
          this.$store.state.loading = false
          this.$store.dispatch('show_snackbar', {
            text: 'No se encontró datos para el extracto buscado.',
            color: 'info',
          })
          return
        }
        // empiezo a llenar los filtros, empresa
        let unaEmp = this.empresas.filter(element => element.id == datosPeticion.extracto[0].empresa_codigo)
        if (unaEmp.length > 0) this.selectedEmpresa = unaEmp[0]
        if (!objetoNoVacio(this.selectedEmpresa)){
          this.$store.state.loading = false
          return
        }
        // banco
        let bancosPeticion = await this.$store.dispatch('conciliacionBancaria/getBancosXEmpresa', {empresa_codigo: this.selectedEmpresa.id})
        if (bancosPeticion.resultado == 0){
          this.$store.state.loading = false
          this.$store.dispatch('show_snackbar', {
            text: bancosPeticion.msj,
            color: 'error',
          })
          return
        }
        this.bancos = bancosPeticion.bancos
        let unBanco = this.bancos.filter(element => element.banco_codigo == datosPeticion.extracto[0].banco_codigo)
        if (unBanco.length > 0) this.selectedBanco = unBanco[0]
        if (!objetoNoVacio(this.selectedBanco)){
          this.$store.state.loading = false
          return
        }
        // cuenta
        let cuenasPeticion = await this.$store.dispatch('conciliacionBancaria/getCuentasBanco', {banco_codigo: this.selectedBanco.banco_codigo})
        if (cuenasPeticion.resultado == 0){
          this.$store.state.loading = false
          this.$store.dispatch('show_snackbar', {
            text: cuenasPeticion.msj,
            color: 'error',
          })
          return
        }
        this.cuentas = cuenasPeticion.cuentas.filter(element => element.empresa_codigo == this.selectedEmpresa.id)
        let unaCuenta = this.cuentas.filter(element => element.cuenta_codigo == datosPeticion.extracto[0].cuenta_codigo)
        if (unaCuenta.length > 0) this.selectedCuenta = unaCuenta[0]
        // id del extracto
        this.extractoId = req
        this.$store.state.loading = false
        // paso a buscar
        this.buscar(2)
      }
    },
    limpiar(){
      this.selectedEmpresa = {}
      this.selectedBanco = {}
      this.selectedCuenta = {}
      this.limpiar_fecha = true
      this.miBand = false;
      this.extractoId = ''
      this.archivoId = ''
      this.selectedEstado = {}
      this.selectedTipoEx = []
      this.selectedTipoMov = {}
    },
    limpiarEst(){
      this.filtrosEst = {
        movimiento_codigo: null,
        empresa_codigo: null,
        banco_codigo: null,
        cuenta_codigo: null,
        tipo_movimiento: null,
        estado: null,
        fecha_desde: null,
        fecha_hasta: null,
        importe_desde: null,
        importe_hasta: null,
        nro_comprobante: null,
        sucursal_codigo: null,
        origen_movimiento: null
      }
    },
    limpiarFiltro(){
      this.filtro = {
        empresa: null,
        sucursal: null,
        ptovta: null,
        banco: null,
        num_mov: null,
        num_comp: null,
        tipo: null,
        origen: null,
        cuenta: null,
        gestor: null,
        forma_cobro: null,
        tarjeta: null,
        auditado: null,
        estado: 1,
        fecha_desde: null,
        fecha_hasta: null
      }
      this.movimiento = {}
    },
    async getBancos(opcion){
      let empresa = 0
      if (opcion == 1){
        if (this.selectedEmpresa == null || this.selectedEmpresa == undefined) return
        this.selectedBanco = {}
        this.bancos = []
        empresa = this.selectedEmpresa.id
      }else{
        if (this.filtrosEst.empresa_codigo == null || this.filtrosEst.empresa_codigo == undefined) return
        this.filtrosEst.banco_codigo = null
        this.bancosEst = []
        empresa = this.filtrosEst.empresa_codigo
      }
      this.$store.state.loading = true
      let bancosPeticion = await this.$store.dispatch('conciliacionBancaria/getBancosXEmpresa', {empresa_codigo: empresa})
      this.$store.state.loading = false
      if (bancosPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: bancosPeticion.msj,
          color: 'error',
        })
        return
      }
      if (opcion == 1) this.bancos = bancosPeticion.bancos
      else this.bancosEst = bancosPeticion.bancos
    },
    async getCuentas(opcion){
      let banco = 0
      let empresa = 0
      if (opcion == 1){
        // banco
        if (this.selectedBanco == null || this.selectedBanco == undefined) return
        this.selectedCuenta = {}
        this.cuentas = []
        banco = this.selectedBanco.banco_codigo
        // empresa
        if (this.selectedEmpresa == null || this.selectedEmpresa == undefined) return
        empresa = this.selectedEmpresa.id
      }else{
        // banco
        if (this.filtrosEst.banco_codigo == null || this.filtrosEst.banco_codigo == undefined) return
        this.filtrosEst.cuenta_codigo = null
        this.cuentasEst = []
        banco = this.filtrosEst.banco_codigo
        // empresa
        if (this.filtrosEst.empresa_codigo == null || this.filtrosEst.empresa_codigo == undefined) return
        empresa = this.filtrosEst.empresa_codigo
      }
      this.$store.state.loading = true
      let cuenasPeticion = await this.$store.dispatch('conciliacionBancaria/getCuentasBanco', {banco_codigo: banco})
      this.$store.state.loading = false
      if (cuenasPeticion.exito == 0){
        this.$store.dispatch('show_snackbar', {
          text: cuenasPeticion.msj,
          color: 'error',
        })
        return
      }
      if (opcion == 1) this.cuentas = cuenasPeticion.cuentas.filter(element => element.empresa_codigo == empresa)
      else this.cuentasEst = cuenasPeticion.cuentas.filter(element => element.empresa_codigo == empresa)
    },
    async buscar(opcion){
      if (!objetoNoVacio(this.selectedEmpresa) || !objetoNoVacio(this.selectedBanco) || !objetoNoVacio(this.selectedCuenta) || cadenaVacia(this.fecha[0])){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar al menos Empresa, Banco, Cuenta y una Fecha inicial para realizar la búsqueda.',
          color: 'info',
        })
        return
      }
      this.searchEx = '';
      this.expanded = []
      this.extractos = []
      // fecha hasta?
      let fechaHasta = ''
      if (!cadenaVacia(this.fecha[1])) fechaHasta = moment(this.fecha[1]).format("DD/MM/YYYY")
      // tipo movimiento extracto
      let signo = null
      if (objetoNoVacio(this.selectedTipoMov)) signo = this.selectedTipoMov.signo
      // archivo id
      let archivo = 0
      if (!cadenaVacia(this.archivoId)) archivo = this.archivoId
      // extracto id
      let extracto = 0
      if (!cadenaVacia(this.extractoId)) extracto = this.extractoId
      // estado conciliacion
      let estadoConcil = 0
      if (objetoNoVacio(this.selectedEstado)) estadoConcil = this.selectedEstado.estado_id
      this.load = true
      this.miBand = true;
      let busquedaPeticion = await this.$store.dispatch('conciliacionBancaria/buscarExtractos', {
        empresa_codigo: this.selectedEmpresa.id,
        banco_codigo: this.selectedBanco.banco_codigo,
        cuenta_codigo: this.selectedCuenta.cuenta_codigo,
        fecha_desde: opcion == 1 ? moment(this.fecha[0]).format("DD/MM/YYYY") : '',
        fecha_hasta: fechaHasta,
        tipo_extracto: this.selectedTipoEx,
        signo: signo,
        archivo_id: archivo,
        estado_concilia: estadoConcil,
        extracto_id: extracto
      })
      this.miBand = false;
      this.load = false
      if (busquedaPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: busquedaPeticion.msj,
          color: 'error',
        })
        return
      }
      this.extractos = busquedaPeticion.extractos
    },
    filtrosVacios(){
      const vacios = Object.values(this.filtrosEst).every(value => {
        if (value == null || value == undefined) return true
        return false
      })
      return vacios
    },
    async buscarEst(){
      if (this.filtrosVacios()){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar al menos un Filtro para realizar la búsqueda.',
          color: 'error',
        })
        return
      }
      this.estimaciones = []
      this.loadEst = true
      let buscarPeticion = await this.$store.dispatch('conciliacionBancaria/buscarMovimientos', this.filtrosEst)
      this.loadEst = false
      if (buscarPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: buscarPeticion.msj,
          color: 'error',
        })
        return
      }
      this.estimaciones = buscarPeticion.movimientos
    },
    confConciliarOK(item){
      if (cadenaVacia(item.observacion)){
        this.$store.dispatch('show_snackbar', {
          text: 'La observación no puede estar vacía.',
          color: 'info',
        })
        return
      }
      this.selectedExtracto = item
      this.msjConfirmar = `Está Conciliando OK el Extracto ${item.id}. ¿Confirma esta acción?`
      this.dialogConciliarOK = true
    },
    async conciliarOK(){
      this.$store.state.loading = true
      let conciliarPeticion = await this.$store.dispatch('conciliacionBancaria/conciliarOK', {
        extracto_id: this.selectedExtracto.id,
        observacion: this.selectedExtracto.observacion
      })
      this.$store.state.loading = false
      if (conciliarPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: conciliarPeticion.msj,
          color: 'error',
        })
        return
      }
      // conciliado correcto, paso a actualizar en el detalle de extractos
      let existe = this.extractos.filter(element => element.id == this.selectedExtracto.id)
      if (existe.length > 0){
        let pos = this.extractos.indexOf(existe[0])
        this.extractos[pos].color = 'success'
        this.extractos[pos].conciliacion_manual = 1
        this.extractos[pos].estado_id = 2
        this.extractos[pos].estado_nombre = 'CONCILIADO OK'
        this.extractos[pos].fecha_concilia = moment(new Date()).format("DD/MM/YYYY")
        this.extractos[pos].mov_conciliado = []
      }
      // limpio el extracto actual
      this.selectedExtracto = {}
      this.$store.dispatch('show_snackbar', {
        text: 'El Extracto fue conciliado exitosamente.',
        color: 'success',
      })
    },
    confConciliarObs(item){
      if (cadenaVacia(item.observacion)){
        this.$store.dispatch('show_snackbar', {
          text: 'La observación no puede estar vacía.',
          color: 'info',
        })
        return
      }
      this.selectedExtracto = item
      this.msjConfirmar = `Está Conciliando con Observación el Extracto ${item.id}. ¿Confirma esta acción?`
      this.dialogConciliarObs = true
    },
    async conciliarObs(){
      this.$store.state.loading = true
      let conciliarPeticion = await this.$store.dispatch('conciliacionBancaria/conciliarObs', {
        extracto_id: this.selectedExtracto.id,
        observacion: this.selectedExtracto.observacion
      })
      this.$store.state.loading = false
      if (conciliarPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: conciliarPeticion.msj,
          color: 'error',
        })
        return
      }
      // conciliado correcto, paso a actualizar en el detalle de extractos
      let existe = this.extractos.filter(element => element.id == this.selectedExtracto.id)
      if (existe.length > 0){
        let pos = this.extractos.indexOf(existe[0])
        this.extractos[pos].color = 'warning'
        this.extractos[pos].conciliacion_manual = 1
        this.extractos[pos].estado_id = 3
        this.extractos[pos].estado_nombre = 'CONCILIADO CON OBS'
        this.extractos[pos].fecha_concilia = moment(new Date()).format("DD/MM/YYYY")
        this.extractos[pos].mov_conciliado = []
      }
      // limpio el extracto actual
      this.selectedExtracto = {}
      this.$store.dispatch('show_snackbar', {
        text: 'El Extracto fue conciliado exitosamente.',
        color: 'success',
      })
    },
    /*confDesconciliar(item){
      this.selectedExtracto = item
      this.msjConfirmar = `Está desconciliando el Extracto ${item.id}. ¿Confirma esta acción?`
      this.dialogDesconciliar = true
    },*/
    controlaModal(item, mov, opc){
      this.selectedExtracto = item

      if(item.concilia_factura == 1){
        if(opc == 1){
          this.objSelec.individual = false; // 2 o más
        } else{ 
          this.objSelec.individual = true; // solo 1
          this.objSelec.mov = mov;
        }
        this.estado_control = 0;
        this.objSelec.dialogSelecEstado = true;
        this.tabla_relacion_id = 2;
      }else{
        this.msjConfirmar = `Está desconciliando el Extracto ${item.id}. ¿Confirma esta acción?`
        this.dialogDesconciliar = true
      }
    },
    //desconcilia todas las facturas de compra asociadas al extracto
    confDesconciliar(){
      this.objSelec.dialogSelecEstado = false;
      if(this.estado_control == null || this.estado_control == undefined){
        return this.$store.dispatch('show_snackbar', { text: 'Se requiere seleccionar el estado de control nuevo', color: 'warning' })
      }
      this.relacion_id = !this.objSelec.individual || (this.objSelec.individual && this.selectedExtracto.mov_conciliado.length==1)? 0 : this.objSelec.mov.movimiento_codigo;
      let cad  = `Está desconciliando el Extracto ${this.selectedExtracto.id} y la factura ${this.objSelec.mov.movimiento_codigo} asociada. ¿Confirma esta acción?`;
      let cad1 = `Está desconciliando el Extracto ${this.selectedExtracto.id} y sus facturas asociadas. ¿Confirma esta acción?`;
      this.msjConfirmar = this.objSelec.individual ? cad : cad1;
      this.dialogDesconciliar = true
    },
    async desconciliar(){
      let obj = {
        extracto_id: this.selectedExtracto.id,
        estado_control: this.estado_control,
        relacion_id: this.relacion_id,
        tabla_relacion_id: this.tabla_relacion_id,
      };

      this.$store.state.loading = true
      let desconciliarPeticion = await this.$store.dispatch('conciliacionBancaria/desconciliar', obj)
      this.$store.state.loading = false
      if (desconciliarPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: desconciliarPeticion.msj,
          color: 'error',
        })
        return
      }

      if(obj.relacion_id != 0){
        let pos = this.extractos.indexOf(this.selectedExtracto);
        let pos1 = this.extractos[pos].mov_conciliado.map(e => e.movimiento_codigo).indexOf(this.relacion_id);
        this.extractos[pos].mov_conciliado.splice(pos1,1);
        this.conciliadoConFactura = this.objSelec.individual ? true : false;
      }else{
        // desconciliación exitosa, paso a marcar como desconciliado al extracto
        let existe = this.extractos.filter(element => element.id == this.selectedExtracto.id)
        if (existe.length > 0){
          let pos = this.extractos.indexOf(existe[0])
          this.extractos[pos].color = 'error'
          this.extractos[pos].conciliacion_manual = 0
          this.extractos[pos].estado_id = 1
          this.extractos[pos].estado_nombre = 'PENDIENTE DE CONCILIAR'
          this.extractos[pos].fecha_concilia = null
          this.extractos[pos].mov_conciliado = []
          this.extractos[pos].observacion = ''
          this.extractos[pos].usuario_concilia = null
          this.extractos[pos].concilia_factura = 0;
        }
        // limpio el extracto actual
        this.selectedExtracto = {}
        this.$store.dispatch('show_snackbar', {
          text: 'El Extracto fue desconciliado exitosamente.',
          color: 'success',
        });
        //reseteo mi variable
        this.conciliadoConFactura = false;
      }
    },
    confConciliarManual(item){
      // 12-01-2024 POR MMURILLO, PEDIMOS OBLIGATORIA LA OBSERVACIÓN PARA CONCILIAR MANUAL
      let existe = this.extractos.filter(element => element.id == this.expanded[0].id)
      if (existe.length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo determinar la posición del extracto seleccionado.',
          color: 'error',
        })
        return
      }
      let pos = this.extractos.indexOf(existe[0])
      this.selectedMovEstimado = item
      this.selectedMovEstimado.observacion = null
      if (this.selectedMovEstimado.tipo == null || this.selectedMovEstimado.tipo == undefined) this.selectedMovEstimado.tipo = 2
      // me guardo la observación del extracto
      this.selectedMovEstimado.observacion = this.extractos[pos].observacion
      // abro el modal de confirmación
      if (item.tipo == 4) this.msjConfirmar = `Está conciliando Manual el Extracto ${this.expanded[0].id} con el Extracto Bancario ${this.selectedMovEstimado.movimiento_codigo}. ¿Confirma esta acción?`
      else this.msjConfirmar = `Está conciliando Manual el Extracto ${this.expanded[0].id} con el Movimiento Bancario ${this.selectedMovEstimado.movimiento_codigo}. ¿Confirma esta acción?`
      this.dialogConfConcManual = true
    },
    async conciliarManual(){
      // cierro el modal
      this.dialogConfConcManual = false
      let obs = null
      if (!cadenaVacia(this.selectedMovEstimado.observacion)) obs = this.selectedMovEstimado.observacion
      this.$store.state.loading = true
      let conciliarPeticion = await this.$store.dispatch('conciliacionBancaria/conciliarManual', {
        extracto_id: this.expanded[0].id,
        movimiento_codigo: this.selectedMovEstimado.movimiento_codigo,
        observacion: obs,
        tipo: this.selectedMovEstimado.tipo
      })
      this.$store.state.loading = false
      if (conciliarPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: conciliarPeticion.msj,
          color: 'error',
        })
        return
      }
      // conciliación exitosa, paso a marcar conciliada a la operación en el array de extractos
      let existe = this.extractos.filter(element => element.id == this.expanded[0].id)
      if (existe.length > 0){
        let pos = this.extractos.indexOf(existe[0])
        this.extractos[pos].mov_conciliado = []
        this.extractos[pos].mov_conciliado.push(this.selectedMovEstimado)
        this.extractos[pos].estado_id = 2
        this.extractos[pos].estado_nombre = 'CONCILIADO OK'
        this.extractos[pos].color = 'success'
        this.extractos[pos].conciliacion_manual = 1
        this.extractos[pos].fecha_concilia = moment(new Date()).format("DD/MM/YYYY")
        this.extractos[pos].diferencia = roundNumber(this.extractos[pos].importe, 2) - roundNumber(this.selectedMovEstimado.importe, 2)
        this.extractos[pos].observacion = obs
        this.$vuetify.goTo('#SeccionExtractos')
      }
      // limpio el listado de estimados
      this.limpiarEst()
      this.estimaciones = []
      // limpio el estimado seleccionado
      this.selectedMovEstimado = {}
      this.$store.dispatch('show_snackbar', {
        text: 'Conciliación terminada con éxito.',
        color: 'success',
      })
    },
    async rowClick (item, row) {
      let existe = this.extractos.filter(element => element.id == item.id)
      let pos = -1
      if (existe.length > 0) pos = this.extractos.indexOf(existe[0])
      if (pos == -1){
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo determinar la posición del elemento en el listado.',
          color: 'error',
        })
        return
      }
      this.estimaciones = []
      let selectState = (row.isSelected) ? false : true
      row.select(selectState)
      if (!row.isSelected){
        this.expanded = []
        this.expanded.push(item)
      }else this.expanded = []
      if (this.expanded.length > 0){
        this.extractos[pos].mov_conciliado = []
        this.limpiarEst()
        this.estimaciones = []
        this.$store.state.loading = true;
        // paso a pedir la info del movimiento bancario conciliado
        let movPeticion = await this.$store.dispatch('conciliacionBancaria/getInfoMovBancario', {
          extracto_id: this.expanded[0].id
        })
        this.$store.state.loading = false
        if (movPeticion.resultado == 0){
          this.$store.dispatch('show_snackbar', {
            text: movPeticion.msj,
            color: 'error',
          })
          return
        }
        if (movPeticion.mov_bancario.length > 0){
          // obtuve el movimiento bancario conciliado
          this.expanded[0].mov_conciliado = movPeticion.mov_bancario
          this.extractos[pos].mov_conciliado = movPeticion.mov_bancario
          this.conciliadoConFactura = (movPeticion.mov_bancario[0].tipo == 5);
        }else{
          // estimaciones
          this.estimaciones = movPeticion.estimaciones
          if (item.estado_id == 1) this.$vuetify.goTo('#SeccionEstimaciones')
        }        
      }
    },
    setModalDesconciliar(value){
      this.dialogDesconciliar = value
    },
    setModalConciliaOK(value){
      this.dialogConciliarOK = value
    },
    setModalConciliaObs(value){
      this.dialogConciliarObs = value
    },
    setModalModObs(value){
      this.dialogModObs = value
    },
    verPedido(item){
      let path = ''
      if(!this.conciliadoConFactura){
        if (item.origen_codigo == 1 || item.origen_codigo == 2 || item.origen_codigo == 3 || item.origen_codigo ==  5 || item.origen_codigo == 6){
          if (item.origen_codigo == 1 || item.origen_codigo == 2 || item.origen_codigo == 3) path = `/control-cajas/${item.empresa_codigo},${item.sucursal_codigo},${item.nro_pedido},2`
          else path = `/control-cajas/${item.empresa_codigo},${item.sucursal_codigo},${item.nro_pedido},null`
        }
      }else{
        path= '/aud-factura-compra/'+item.movimiento_codigo;
      }
      let routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    async modificarMovimiento(mov){
      if (cadenaVacia(mov.empresa_codigo) || mov.empresa_codigo == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo determinar la empresa del movimiento bancario a modificar. Revisar.',
          color: 'info',
        })
        return
      }
      this.limpiarFiltro()
      this.filtro.empresa = mov.empresa_codigo
      this.filtro.num_mov = mov.movimiento_codigo
      this.filtro.estado = 1
      this.$store.state.loading = true
      let datosMovPeticion = await this.$store.dispatch('movBancarios/get_movimientos', this.filtro)
      this.$store.state.loading = false
      if (datosMovPeticion.exito == 0){
        this.$store.dispatch('show_snackbar', {
          text: datosMovPeticion.message,
          color: 'error',
        })
        return
      }
      // obtuve la info?
      if (datosMovPeticion.data.length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'No se obtuvo la info del movimiento bancario. Revisar.',
          color: 'info',
        })
        return
      }
      // asigno la info
      this.movimiento = JSON.parse(JSON.stringify(datosMovPeticion.data[0]))
      this.dialogModMovBco = true
    },
    async modMovimiento(mov){
      // tengo el registro en expanded, paso a refrescar el contenido
      this.expanded[0].mov_conciliado = []
      // lo marco en el listado tambien
      let existe = this.extractos.filter(element => element.id == this.expanded[0].id)
      if (existe.length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo determinar la posición del extracto en el listado. Repita nuevamente la búsqueda e intente de nuevo.',
          color: 'error',
        })
        return
      }
      let pos = this.extractos.indexOf(existe[0])
      this.extractos[pos].mov_conciliado = []
      // continuo como si fuera el buscar
      this.limpiarEst()
      this.estimaciones = []
      // paso a pedir la info del movimiento bancario conciliado
      this.$store.state.loading = true
      let movPeticion = await this.$store.dispatch('conciliacionBancaria/getInfoMovBancario', {
        extracto_id: this.expanded[0].id
      })
      this.$store.state.loading = false
      if (movPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: movPeticion.msj,
          color: 'error',
        })
        return
      }
      if (movPeticion.mov_bancario.length > 0){
        // obtuve el movimiento bancario conciliado
        this.expanded[0].mov_conciliado = movPeticion.mov_bancario
        this.extractos[pos].mov_conciliado = movPeticion.mov_bancario
      }
    },
    confGuardarObs(item){
      if (cadenaVacia(item.observacion)){
        this.$store.dispatch('show_snackbar', {
          text: 'La observación no puede estar vacía.',
          color: 'info',
        })
        return
      }
      this.selectedExtracto = item
      this.msjConfirmar = `Está modificando la observación el Extracto ${item.id}. ¿Confirma esta acción?`
      this.dialogModObs = true
    },
    async guardarObs(){
      this.$store.state.loading = true
      let savePeticion = await this.$store.dispatch('conciliacionBancaria/guardarObservacion', {
        extracto_id: this.selectedExtracto.id,
        observacion: this.selectedExtracto.observacion
      })
      this.$store.state.loading = false
      if (savePeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'Se guardó la observación correctamente.',
          color: 'success',
        })
      }else{
        this.$store.dispatch('show_snackbar', {
          text: savePeticion.msj,
          color: 'error',
        })
      }
      this.selectedExtracto = {}
    },
    //Controla el tamaño para el Componente de fecha Rango
    changeSize (custom) {
      this.big = custom
    },
    //Controla quien es la conciliacion actual, para el click derecho
    controlarActual(item){
      this.actualConciliacion = item;
    },
    //Accion del click derecho
    clickDerecho(event, item){
      event.preventDefault()
      this.actualConciliacion = {}
      this.menu.activo = false
      this.menu.x = event.clientX
      this.menu.y = event.clientY
      this.$nextTick(() => {
        this.menu.activo = true
        this.actualConciliacion = item.item
      })
    },
    //pinta la row de color
    ponerColor(item){
      return item.id === this.actualConciliacion.id && this.actualConciliacion.estado_id == 1? 'background-color: rowsel' : '';
    },
    //despinta la row
    ponerSinColor(){
      return '';
    },
    //abre el modal y carga el obj pasado como parámetro
    conciliarCompraModal(){
      this.objModal.activo = true;
      this.objModal.infoExtracto = this.actualConciliacion;
    },
    //recibe la información emitida y realiza la consulta para conciliar la compra
    async conciliarCompra(listConciliar, extracto){
      let cadId = listConciliar.map(e => e.factura_id).join(', ');

      this.$store.state.loading = true
      let peticion = await this.$store.dispatch('conciliacionBancaria/conciliarCompras', {
        facturas: listConciliar,
        id_extracto: extracto,
        cadena_fact_ids: cadId
      })
      this.$store.state.loading = false
      
      if(peticion.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: peticion.message, color: 'error' })
      }

      // conciliado correcto, paso a actualizar en el detalle de extractos
      let existe = this.extractos.filter(element => element.id == extracto)
      if (existe.length > 0){
        let pos = this.extractos.indexOf(existe[0])
        this.extractos[pos].color = 'success'
        this.extractos[pos].conciliacion_manual = 1
        this.extractos[pos].estado_id = 2
        this.extractos[pos].estado_nombre = 'CONCILIADO OK'
        this.extractos[pos].fecha_concilia = moment(new Date()).format("DD/MM/YYYY")
        this.extractos[pos].mov_conciliado = [];
        this.extractos[pos].concilia_factura = 1;
      }

      this.$swal.fire({
        icon: 'success', 
        title: 'Conciliacion exitosa', 
        text: `Se ha conciliado el extracto ${extracto} con las facturas de compra: ${cadId}`,
        timer: 3000
      });

      this.objModal.activo = false;
      this.conciliadoConFactura = true;
    }
  },
  components: {
    FechaPickerRango,
    BtnFiltro,
    SearchDataTable,
    FechaPicker,
    ModalConfirmar,
    ModificarMovBancario,
    ModalGetFactCompraVue
  },
  computed:{

  },
  watch: {
    selectedEmpresa: function(){
      if (this.selectedEmpresa == null || this.selectedEmpresa == undefined) this.selectedEmpresa = {}
      if (Object.keys(this.selectedEmpresa).length == 0){
        this.selectedBanco = {}
        this.bancos = []
        this.selectedCuenta = {}
        this.cuentas = []
      }
    },
    selectedBanco: function(){
      if (this.selectedBanco == null || this.selectedBanco == undefined) this.selectedBanco = {}
      if (Object.keys(this.selectedBanco).length == 0){
        this.selectedCuenta = {}
        this.cuentas = []
      }
    },
    selectedCuenta: function(){
      if (this.selectedCuenta == null || this.selectedCuenta == undefined) this.selectedCuenta = {}
    },
    selectedTipoEx: function(){
      if (this.selectedTipoEx == null || this.selectedTipoEx == undefined) this.selectedTipoEx = []
    },
    selectedEstado: function(){
      if (this.selectedEstado == null || this.selectedEstado == undefined) this.selectedEstado = {}
    },
    selectedTipoMov: function(){
      if (this.selectedTipoMov == null || this.selectedTipoMov == undefined) this.selectedTipoMov = {}
    },
    extractos: function(){
      if (this.extractos.length > 0){
        for (let id in this.extractos){
          this.extractos[id].fecha = cadenaVacia(this.extractos[id].fecha) ? '' : moment(this.extractos[id].fecha).format("DD/MM/YYYY")
          this.extractos[id].fecha_concilia = cadenaVacia(this.extractos[id].fecha_concilia) ? '' : moment(this.extractos[id].fecha_concilia).format("DD/MM/YYYY")
          this.extractos[id].fecha_grabacion = cadenaVacia(this.extractos[id].fecha_grabacion) ? '' : moment(this.extractos[id].fecha_grabacion).format("DD/MM/YYYY")
        }
      }
    },
    conciliadoConFactura(val){
      if(!val){
        this.relacion_id    = 0;
        //this.estado_control = 0;
      }
    }
  },
}
</script>

<style scoped>
  .v-data-table::v-deep td {
    font-size: 13px !important;
  }
</style>