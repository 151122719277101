<template>
  <v-chip-group
    v-model="dias"
    multiple
    column
  >
    <v-chip
      v-for="(item, index) in dias_semana"
      :key="index"
      filter-icon="fas fa-check-circle"
      :color="disabled ? '' : 'primary'"
      :disabled="disabled"
      outlined
      filter
    >
      {{ nombre_corto ? item.nombre_corto : item.nombre }}
    </v-chip>
  </v-chip-group>
</template>

<script>
/**
 *  Componente para seleccionar los dias de la semana de un chip group
 */
import { mapState } from 'vuex'

export default {
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('genericosAcc/get_dias')
    this.$store.state.loading = false
  },
  props: {
    // si se envia en true se muestra el nombre corto de los dias
    nombre_corto: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: Array // array los dias seleccionados, puede enviarse vacio (obligatorio)
  },
  computed: {
    ...mapState('genericosAcc', ['dias_semana']),
    dias: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>