<template>
  <div>
    <v-row class="d-flex justify-center my-0">
      <!-- Titulo del Formulario -->
      <v-col cols="12" class="d-flex justify-end align-center pa-0 ml-8">
        <div>Remito N° {{ $route.params.numero }}</div>
        <v-spacer></v-spacer>
      </v-col>
      <!-- Contenido -->
      <v-col cols="12" class="px-sm-3 px-0">
        <v-card class="pb-2" flat>
          <v-card-text>
            <!-- Nro Remito, Emision-Numero, Estado y Fecha -->
            <v-row>
              <v-col cols="6" sm="3" md="3" class="py-0">
                <v-text-field
                  v-model="remitoNumero"
                  label="Remito Número"
                  outlined
                  dense
                  type="number"
                  readonly
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="3" class="py-0">
                <v-text-field
                  v-model="emisionNumero"
                  label="Emisión - Número"
                  outlined
                  dense
                  type="text"
                  readonly
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="py-0">
                <v-text-field
                  v-model="estado"
                  label="Estado"
                  outlined
                  dense
                  type="text"
                  readonly
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="3" class="py-0">
                <v-text-field
                  v-model="fecha"
                  label="Fecha"
                  outlined
                  dense
                  type="text"
                  readonly
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Referencia -->
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  v-model="referencia"
                  label="Referencia"
                  outlined
                  dense
                  type="text"
                  readonly
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Bodega Origen, Bodega Destino, Usuario Grabación y Usuario Aceptación -->
            <v-row>
              <v-col cols="12" sm="4" md="4" class="py-0">
                <v-text-field
                  v-model="bodegaOrigen"
                  label="Bodega Origen"
                  outlined
                  dense
                  type="text"
                  readonly
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="py-0">
                <v-text-field
                  v-model="bodegaDestino"
                  label="Bodega Destino"
                  outlined
                  dense
                  type="text"
                  readonly
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="2" md="2" class="py-0">
                <v-text-field
                  v-model="usuarioGrabacion"
                  label="Usuario Grabación"
                  outlined
                  dense
                  type="text"
                  readonly
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="2" md="2" class="py-0">
                <v-text-field
                  v-model="usuarioAceptacion"
                  label="Usuario Aceptación"
                  outlined
                  dense
                  type="text"
                  readonly
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Listado de Artículos remitidos -->
            <v-row>
              <v-col cols="12" class="py-0">
                <v-card>
                  <v-card-title class="py-2">
                    Artículos Remitidos
                    <v-spacer></v-spacer>
                    <!-- Buscador en el datatable -->
                    <v-row class="d-flex justify-end" no-gutters>
                      <v-col cols="12" sm="6">
                        <SearchDataTable
                          v-model="search"
                        />
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-text class="pa-0">
                    <v-data-table
                      class="cebra"
                      :headers="headers"
                      :items="detalles"
                      :loading="load"
                      :search="search"
                      dense
                    >
                      <!-- Series -->
                      <template v-slot:[`item.series`]="{ item }">
                        <v-btn icon small @click="verSeries(item)" v-if="item.solicita_serie == 1"><v-icon small color="success" title="Series">fas fa-tasks</v-icon></v-btn>
                      </template>
                      <!-- Estado -->
                      <template v-slot:[`item.remitido`]="{ item }">
                        <v-chip
                          v-if="estado_codigo != 1"
                          :color="item.remitido == 1 ? 'success' : 'error'"
                          small
                        >
                          {{ item.remitido == 1 ? 'Aceptado' : 'No aceptado' }}
                        </v-chip>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-end mx-2">
            <v-row class="d-flex justify-end py-4 px-3">
              <v-btn color="warning" class="mb-2" @click="volverIndex()">Volver</v-btn>
              <div class="ml-3 mb-2" v-if="estado_codigo != 1 && !load">
                <v-btn
                  color="error"
                  @click="generarPDF()"
                >
                  <v-icon small left>fas fa-file-pdf</v-icon>
                  PDF Remito
                </v-btn>
              </div>
              <div class="ml-3 mb-2" v-if="(estado_codigo == 2 || estado_codigo == 3) && esLocal == 1">
                <v-btn
                  color="primary"
                  @click="descargarEtiquetas()"
                >
                  <v-icon small left>fas fa-ticket-alt</v-icon>
                  Etiquetas precio
                </v-btn>
              </div>
              <div class="ml-3 mb-2" v-if="estado_codigo == 1">
                <BtnConfirmar
                  nombre="Anular"
                  color="error"
                  :sin_icono="true"
                  texto="¿Está seguro de anular el remito?"
                  @action="anularRemito(1)"
                />
              </div>
              <div class="ml-3 mb-2" v-if="estado_codigo == 1">
                <BtnConfirmar
                  nombre="Anular y Volver a Cargar"
                  color="indigo"
                  :sin_icono="true"
                  dark
                  texto="Tras la anulación se permitirá crear un remito idéntico en que caso que lo necesite, ¿está seguro de anular el remito?"
                  @action="anularRemito(2)"
                />
              </div>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- Modal Series -->
    <Series 
      :dialogActivo="dialogSeries"
      :articuloCodigo="parseInt(articuloCodSerie)"
      :articuloNombre="articuloNomSerie"
      :seriesRemito="seriesArticulo"
      @setearModalSeries="setModalSeries"
    />
    <!-- visor de pdf -->
    <PDFViewer
      v-model="pdf_dialog"
      :pdf="pdf"
      :nombre="pdf_nombre"
      :titulo="pdf_nombre"
    />
  </div>
</template>

<script>
import { etiquetasPrecio, reporteRemitoInterno } from '../../util/plantillas/pdfs'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import BtnConfirmar from '../../components/util/BtnConfirmar.vue'
import Series from '../../components/generales/Series.vue'
import router from '../../router'
import PDFViewer from '../../util/plantillas/PDFViewer'
import moment from 'moment'

export default {
  data() {
    return {
      remitoNumero: '',
      emisionNumero: '',
      estado: '',
      esLocal: 0,
      estado_codigo: 0,
      fecha: '',
      referencia: '',
      bodegaOrigen: '',
      bodegaDestino: '',
      usuarioGrabacion: '',
      usuarioAceptacion: '',
      headers: [
        { text: 'Artículo Código', align: 'center', value: 'articulo_codigo' },
        { text: 'Descripción', align: 'left', value: 'articulo_nombre' },
        { text: 'Cantidad', align: 'center', value: 'cantidad' },
        { text: 'Estado', align: 'center', value: 'remitido' },
        { text: 'Series', align: 'center', value: 'series' },
      ],
      detalles: [],
      series: [],
      load: false,
      search: '',
      dialogSeries: false,
      articuloCodSerie: '',
      articuloNomSerie: '',
      seriesArticulo: [],
      pdf_dialog: false,
      pdf_nombre: '',
      pdf: null,
    }
  },
  components: {
    SearchDataTable,
    BtnConfirmar,
    Series,
    PDFViewer
  },
  created () {
    this.initForm()
  },
  methods: {
    async initForm(){
      this.$store.state.loading = true
      this.load = true
      let remitoPeticion = await this.$store.dispatch('remitos/getUnRemito', {numero: this.$route.params.numero})
      this.$store.state.loading = false
      this.load = false
      if (remitoPeticion.resultado == 1){
        this.remitoNumero = remitoPeticion.remito[0].remito_numero
        this.emisionNumero = remitoPeticion.remito[0].emision_numero
        this.estado = remitoPeticion.remito[0].estado_nombre
        this.estado_codigo = remitoPeticion.remito[0].estado_codigo
        this.fecha = remitoPeticion.remito[0].fecha
        this.referencia = remitoPeticion.remito[0].referencia
        this.bodegaOrigen = remitoPeticion.remito[0].bodega_origen_nombre
        this.bodegaDestino = remitoPeticion.remito[0].bodega_destino_nombre
        this.usuarioGrabacion = remitoPeticion.remito[0].usuario_grabacion
        this.usuarioAceptacion = remitoPeticion.remito[0].usuario_estado
        this.esLocal = remitoPeticion.remito[0].local
        this.detalles = remitoPeticion.detalles
        this.series = remitoPeticion.series
      }else{
        this.$store.dispatch('show_snackbar', {
          text: remitoPeticion.msj,
          color: 'error',
        })
        setTimeout(() => [router.push({name: 'Home'})], 4000)
      }
    },
    verSeries(item){
      this.articuloCodSerie = item.articulo_codigo
      this.articuloNomSerie = item.articulo_nombre
      this.seriesArticulo = this.series.filter(element => element.articulo_codigo == item.articulo_codigo)
      // abrir el modal
      this.dialogSeries = true
    },
    volverIndex(){
      router.push({name: 'Remitos Internos'})
    },
    async anularRemito(opcion){
      this.$store.state.loading = true
      let anulacionPeticion = await this.$store.dispatch('remitos/anularRemito', {remito_numero: this.remitoNumero})
      this.$store.state.loading = false
      if (anulacionPeticion.resultado == 1){
        // anulado con exito
        this.$store.dispatch('show_snackbar', {
          text: 'Remito Anulado con Éxito.',
          color: 'success',
        })
        this.estado = 'ANULADO'
        if (opcion != 1){
          // prosigo con la carga del nuevo remito
        }
      }else if (anulacionPeticion.resultado == 2){
        // el remito no puede ser anulado
        this.$store.dispatch('show_snackbar', {
          text: anulacionPeticion.msj,
          color: 'info',
        })
        setTimeout(() => [location.reload()], 3000)
      }else{
        // ocurrio un error al intentar anular
        this.$store.dispatch('show_snackbar', {
          text: anulacionPeticion.msj,
          color: 'error',
        })
      }
    },
    setModalSeries(value){
      this.dialogSeries = value
      if (this.dialogSeries == false){
        this.articuloCodSerie = ''
        this.articuloNomSerie = ''
        this.seriesArticulo = []
      }
    },
    async generarPDF () {
      this.$store.state.loading = true
      let reportePeticion = await this.$store.dispatch('remitos/getRemitoPDF', { remito_numero: this.remitoNumero })
      this.$store.state.loading = false
      if (reportePeticion.resultado == 1) {
        if (reportePeticion.enabezado.length > 0) {
          // formateo las fechas
          reportePeticion.enabezado[0].fecha = moment(reportePeticion.enabezado[0].fecha).format('DD/MM/YYYY')
          reportePeticion.enabezado[0].fecha_grabacion = moment(reportePeticion.enabezado[0].fecha_grabacion).format('DD/MM/YYYY')
          
          this.$store.state.loading = true
          let pdfPeticion = await reporteRemitoInterno({
            enc: reportePeticion.enabezado[0],
            detalles: reportePeticion.detalles
          })
          this.$store.state.loading = false

          if (pdfPeticion.resultado == 1) {
            this.pdf_nombre = 'Remito N°' + this.remitoNumero
            this.pdf = pdfPeticion.pdf
            this.pdf_dialog = true
          }
          else {
            this.$store.dispatch('show_snackbar', {
              text: pdfPeticion.msj,
              color: 'error'
            })
          }
        }
        else {
          this.$store.dispatch('show_snackbar', {
            text: 'El remito sobrepasa la antiguedad establecida.',
            color: 'info'
          })
        }
      }
      else {
        this.$store.dispatch('show_snackbar', {
          text: reportePeticion.msj,
          color: 'error'
        })
      }
    },
    async descargarEtiquetas () {
      this.$store.state.loading = true
      await this.$store.dispatch('remitos/get_etiquetas', this.remitoNumero)
        .then(async (res) => {
          this.pdf = await etiquetasPrecio(res.data)
          this.pdf_nombre = `Etiquetas precio Remito Nº ${this.remitoNumero}`
          this.pdf_dialog = true
        })
      this.$store.state.loading = false
    }
  },
}
</script>

<style>

</style>